// react 
import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
// .........

// scss file
import './syncmonitoring.scss';
// .........

// package components
// .........

// child components
import { useGlobalState } from '../../Statecontext';
import { ZTable2 } from '../../components/table2';
// .........

// icons
import filterIcon from '../../assets/images/filter.svg';
import { serviceConfig } from '../../core/services';
import Breadcrumbs from '../../shared/Breadcrumb';
import { Modal, Table, Tag } from 'antd';
// .........

export const Syncmonitoring = () => {

    // form values states
    const [usersList, setUsersList] = useState<any>([])
    // ........

    // data states
    const [detailSource, setDetailsource] = useState<any>([]);
    // ..........

    // loading & Modal states
    const [loading, setLoading] = useState(false);
    const [detailloading, setDetailloading] = useState(false);
    const [detailopen, setDetailopen] = useState(false);
    // .........

    // reference states
    const [monitordata, setMonitorData] = useState<any>([])
    const [syncedCount, setSyncedCount] = useState<any>([])
    const navigate = useNavigate()
    // .........

    // limit & filter states
    const { setBreadcrumbPath, filterQuery, searchquery, page, totalpages, setTotalpages, rowsPerPage, userdata, setUserdata } = useGlobalState();
    // .........

    // button details props
    const buttondetails: any = [
        {
            type: 'download',
            name: 'Download',
            theme: 'theme4',
            conditions: ['if_list'],
            downloadquery: 'sync_monitor'
        },
    ]
    // .........

    // table columns and filter requirement props
    const columns: any = [
        {
            title: 'S No.',
            dataIndex: '',
            render(value: any, record: any, index: any) {
                return (
                    <span>{index + 1}</span>
                )
            }
        },
        {
            title: 'Sync Start Time',
            dataIndex: 'created_at',
            // filters: userGSTMaster.map((item: any) => {
            // 	return {
            // 		text: item,
            // 		value: item,
            // 	};
            // }),
            filterIcon: () => <img src={filterIcon} alt='filter icon' />,
            // filterDropdownOpen: gstList.length > 0,
            filterSearch: true,
            render(value: any, record: any, index: any) {
                function join(date: any, options: any, separator: any) {
                    function format(option: any) {
                        let formatter = new Intl.DateTimeFormat('en', option);
                        return formatter.format(date);
                    }
                    let joined = options.map(format).join(separator);
                    return joined + ', ' + new Date(value).toLocaleTimeString();
                }

                let options = [{ day: 'numeric' }, { month: 'short' }, { year: 'numeric' }];
                return value ? join(new Date(value), options, ' ') : '--';
                // return value ? value : '--'
            },
        },
        {
            title: 'Sync End Time',
            dataIndex: 'sync_end',
            // filters: userGSTMaster.map((item: any) => {
            // 	return {
            // 		text: item,
            // 		value: item,
            // 	};
            // }),
            filterIcon: () => <img src={filterIcon} alt='filter icon' />,
            // filterDropdownOpen: gstList.length > 0,
            filterSearch: true,
            render(value: any, record: any, index: any) {
                function join(date: any, options: any, separator: any) {
                    function format(option: any) {
                        let formatter = new Intl.DateTimeFormat('en', option);
                        return formatter.format(date);
                    }
                    let joined = options.map(format).join(separator);
                    return joined + ', ' + new Date(value).toLocaleTimeString();
                }

                let options = [{ day: 'numeric' }, { month: 'short' }, { year: 'numeric' }];
                return value ? join(new Date(value), options, ' ') : '--';
                // return value ? value : '--'
            },
        },
        {
            title: 'User Name',
            dataIndex: 'user',
            // filters: userTradeMaster.map((item: any) => {
            // 	return {
            // 		text: item,
            // 		value: item,
            // 	};
            // }),
            filterIcon: () => <img src={filterIcon} alt='filter icon' />,
            // filterDropdownOpen: gstList.length > 0,
            filterSearch: true,
            render(value: any, record: any, index: any) {
                return value ? value.user_name : '--'
            },
        },
        // {
        //     title: 'E-mail',
        //     dataIndex: 'email',
        //     // filters: userStateMaster.map((item: any) => {
        //     // 	return {
        //     // 		text: item,
        //     // 		value: item,
        //     // 	};
        //     // }),
        //     filterIcon: () => <img src={filterIcon} alt='filter icon' />,
        //     // filterDropdownOpen: gstList.length > 0,
        //     filterSearch: true,
        //     render(value: any, record: any, index: any) {
        //         return value ? value : '--'
        //     },
        // },
        {
            title: 'No. of GSTIN synced',
            dataIndex: 'gst_list',
            // filters: userTradeMaster.map((item: any) => {
            // 	return {
            // 		text: item,
            // 		value: item,
            // 	};
            // }),
            filterIcon: () => <img src={filterIcon} alt='filter icon' />,
            // filterDropdownOpen: gstList.length > 0,
            filterSearch: true,
            render(value: any, record: any, index: any) {
                return value ? value.length : '--'
            },
        },
        {
            title: 'No. of notices fetched',
            dataIndex: 'gst_list',
            // filters: userStateMaster.map((item: any) => {
            // 	return {
            // 		text: item,
            // 		value: item,
            // 	};
            // }),
            filterIcon: () => <img src={filterIcon} alt='filter icon' />,
            // filterDropdownOpen: gstList.length > 0,
            filterSearch: true,
            render(value: any, record: any, index: any) {
                return value ? value.map((obj: any) => obj.notice_count).reduce((accumulator: any, current: any) => accumulator + current, 0) : '--'
            },
        },
        {
            title: 'Total Time for sync (M:S)',
            dataIndex: 'email',
            // filters: userStateMaster.map((item: any) => {
            // 	return {
            // 		text: item,
            // 		value: item,
            // 	};
            // }),
            filterIcon: () => <img src={filterIcon} alt='filter icon' />,
            // filterDropdownOpen: gstList.length > 0,
            filterSearch: true,
            render(value: any, record: any, index: any) {
                function millisToMinutesAndSeconds(millis: any) {
                    var minutes = Math.floor(millis / 60000);
                    var seconds: any = ((millis % 60000) / 1000).toFixed(0);
                    return (minutes < 10 ? '0' : '') + minutes + ":" + (seconds < 10 ? '0' : '') + seconds;
                }

                let sync_end: any = new Date(record.sync_end)
                let sync_start: any = new Date(record.created_at)
                let diffMs = sync_end - sync_start
                return <span onClick={() => { setDetailopen(true); setDetailsource(record.gst_list) }} style={{ fontWeight: 'bold', color: '#E96919', cursor: 'pointer' }}>{record.sync_processed ? millisToMinutesAndSeconds(diffMs) : '--'}</span>
            },
        },
        // {
        //     title: 'Sync Time per GSTIN',
        //     dataIndex: 'email',
        //     // filters: userStateMaster.map((item: any) => {
        //     // 	return {
        //     // 		text: item,
        //     // 		value: item,
        //     // 	};
        //     // }),
        //     filterIcon: () => <img src={filterIcon} alt='filter icon' />,
        //     // filterDropdownOpen: gstList.length > 0,
        //     filterSearch: true,
        //     render(value: any, record: any, index: any) {
        //         return value ? value : '--'
        //     },
        // },
    ];

    const detailcolumns = [
        {
            title: 'S.No',
            dataIndex: 'serial',
            key: 'serial',
            render: (text: any, record: any, index: number) => index + 1,
        },
        {
            title: 'User Name',
            dataIndex: 'gst',
            render(value: any, record: any, index: any) {
                return value.user_name
            }
        },
        {
            title: 'Start Time',
            dataIndex: 'sync_started',
            key: 'sync_started',
            render(value: any, record: any, index: any) {
                function join(date: any, options: any, separator: any) {
                    function format(option: any) {
                        let formatter = new Intl.DateTimeFormat('en', option);
                        return formatter.format(date);
                    }
                    let joined = options.map(format).join(separator);
                    return joined + ', ' + new Date(value).toLocaleTimeString();
                }

                let options = [{ day: 'numeric' }, { month: 'short' }, { year: 'numeric' }];
                return value ? join(new Date(value), options, ' ') : '--';
            },
        },
        {
            title: 'End Time',
            dataIndex: 'sync_end',
            key: 'sync_end',
            render(value: any, record: any, index: any) {
                function join(date: any, options: any, separator: any) {
                    function format(option: any) {
                        let formatter = new Intl.DateTimeFormat('en', option);
                        return formatter.format(date);
                    }
                    let joined = options.map(format).join(separator);
                    return joined + ', ' + new Date(value).toLocaleTimeString();
                }

                let options = [{ day: 'numeric' }, { month: 'short' }, { year: 'numeric' }];
                return value ? join(new Date(value), options, ' ') : '--';
            },
        },
        {
            title: 'Status', dataIndex: 'is_sync_failed', key: 'is_sync_failed',
            render(value: any, record: any, index: any) {
                if (value === true) {
                    return <Tag color='error' style={{ borderRadius: "25px", padding: '3px 10px', cursor: 'pointer' }} >Fail</Tag>
                } else {
                    return <Tag color='success' style={{ borderRadius: "25px", padding: '3px 10px', cursor: 'pointer' }} >Success</Tag>
                }
            },
            width: 100,
        },
        {
            title: 'Total Time for sync (M:S)',
            dataIndex: 'email',
            render(value: any, record: any, index: any) {
                function millisToMinutesAndSeconds(millis: any) {
                    var minutes = Math.floor(millis / 60000);
                    var seconds: any = ((millis % 60000) / 1000).toFixed(0);
                    return (minutes < 10 ? '0' : '') + minutes + ":" + (seconds < 10 ? '0' : '') + seconds;
                }

                let sync_end: any = new Date(record.sync_end)
                let sync_start: any = new Date(record.sync_started)
                let diffMs = sync_end - sync_start
                return <span style={{ fontWeight: 'bold', color: '#E96919' }}>{record.sync_started && record.sync_end ? millisToMinutesAndSeconds(diffMs) : '--'}</span>
            },
        },
    ];
    // .........

    const loadMonitorData = useCallback(() => {
        let query: any = {
            by_page: true,
            page: page,
            page_size: rowsPerPage
        }

        if (searchquery) {
            query['search'] = searchquery;
        }
        setLoading(true)
        serviceConfig.get("gst_sync", true, query, null).then((data: any) => {
            setLoading(false)
            setMonitorData(data.results || data.data)
            setTotalpages(data.count)
        }, (err: any) => {
            console.log(err)
            setLoading(false)
            if (err.message === "Request failed with status code 401") {
                navigate('/login')
                localStorage.clear()
                window.location.reload();
            }
        })
    }, [page, rowsPerPage, filterQuery, searchquery])

    const loadSyncCount = useCallback(() => {
        serviceConfig.get("gst_sync_count_data", true, null, null).then((data: any) => {
            setSyncedCount(data.data)
        }, (err: any) => {
            console.log(err)
        })
    }, [])

    useEffect(() => {
        loadMonitorData()
        loadSyncCount()
    }, [loadMonitorData, loadSyncCount])

    useEffect(() => {
        setBreadcrumbPath('home/services/sync monitoring')
    }, [])

    return (
        <>
            <Modal
                title="Sync Details"
                onCancel={() => { setDetailopen(false); setDetailsource([]) }}
                className='upload-popup'
                width={'auto'}
                centered
                style={{ color: "#031742", minWidth: '30%' }}
                open={detailopen}
                okButtonProps={{ style: { display: 'none' } }}
                cancelButtonProps={{ style: { display: 'none' } }}
            >
                <Table
                    className='details-table'
                    loading={detailloading}
                    style={{ minWidth: '35vw', maxWidth: '100%', marginTop: '3%' }}
                    columns={detailcolumns}
                    dataSource={detailSource}
                // Other props for Table component
                />
            </Modal >
            <div className='sync-monitoring-container'>
                <div style={{ marginTop: '1%' }}>
                    <Breadcrumbs />
                </div>
                <ZTable2 label={'Sync Monitoring'} button={buttondetails} columns={columns} expand={false} hideSelect={true} loading={loading} tabledata={monitordata} totalpages={totalpages} syncedCountsData={syncedCount} />
            </div>
        </>
    )
}