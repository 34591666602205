
import { Terminal } from "../../modules/terminal";

import { Login } from "../../modules/terminal/login";
import { Register } from "../../modules/terminal/register";
import { ForgotPassword } from "../../modules/terminal/forgot-password";

import { Home } from "../../modules/home";

import { GstDashboard } from "../../modules/gstDashboard";
import { SolutionDashboard } from "../../modules/solutionDashboard";
import { DueDashboard } from "../../modules/dueDashboard";
import { ManageGst } from "../../modules/manageGst";
import { Users } from "../../modules/users";

import { SetPassword } from "../../modules/terminal/set-password";
import { PasswordSuccess } from "../../modules/terminal/pwd-success";
import { MailTemplate } from "../../modules/terminal/mail-success";
import { PrivacyPolicy } from "../../modules/terminal/privacy-policy";
import { Compliance } from "../../modules/compliance";
import { Casessumary } from "../../modules/casesummary";
import { ViewNotices } from "../../modules/viewNotice";
import { Userapproval } from "../../modules/userapproval";
import { Phpartners } from "../../modules/phpartners";
import { Phdashboard } from "../../modules/phdashboard";
import { Syncmonitoring } from "../../modules/syncmonitoring";
import { Gstloan } from "../../modules/gstloan";
import { Landing } from "../../shared/landing";
import { ZviewGstInfo } from "../../shared/zviewGstinfo";
import { Fullchat } from "../../shared/zviewGstinfo/fullchat";
import { Overview } from "../../shared/Overview";
import { Otpverify } from "../../modules/terminal/otp-verify";
import { MailVerify } from "../../modules/terminal/mail-verify";
import { Suboverview } from "../../shared/SubOverview";
import { Newdashboard } from "../../modules/newDashboard";
import { Latestupdates } from "../../modules/latestupdates";
import { Allnotice } from "../../modules/allnotice";
import { Allgstin } from "../../modules/allgstin";
import { UserProfile } from "../../shared/profile";

const MainRoutes = {
    path: '/',
    element: <Landing />,
    children: [
        {
            path: '/',
            element: <Terminal />,
            children: [
                {
                    path: '',
                    element: <Login />,
                },
                {
                    path: 'login',
                    element: <Login />,
                },
                {
                    path: 'register',
                    element: <Register />,
                },
                {
                    path: 'forgot-password',
                    element: <ForgotPassword />,
                },
                {
                    path: 'mail-success',
                    element: <MailTemplate />,
                },
                {
                    path: 'otp-verify',
                    element: <Otpverify />
                },
                {
                    path: 'mail-verify',
                    element: <MailVerify />
                },
                {
                    path: 'set-password',
                    element: <SetPassword />
                },
                {
                    path: 'pwd-success',
                    element: <PasswordSuccess />
                }
                ,

            ]
        },
        {
            path: 'home',
            element: <Home />,
            children: [
                {
                    path: '',
                    element: <Newdashboard />
                    // element: <GstDashboard />
                }, {
                    path: "users",
                    element: <Users />
                },
                {
                    path: "gst-dashboard",
                    element: <Newdashboard />
                    //  <GstDashboard />,
                }, {
                    path: "solution-dashboard",
                    element: <SolutionDashboard />
                }, {
                    path: "due-dashboard",
                    element: <DueDashboard />
                },
                {
                    path: "gst-loan",
                    element: <Gstloan />
                },
                {
                    path: "compliance",
                    element: <Compliance />
                }, {
                    path: "manage-gst",
                    element: <ManageGst />
                }, {
                    path: ":id/view-gst-details",
                    element: <ZviewGstInfo />
                },
                {
                    path: ":id/view-solution-details",
                    element: <ZviewGstInfo />
                },
                {
                    path: ":id/chat/:taskid",
                    element: <Fullchat />
                },
                {
                    path: ':id/case-summary-details',
                    element: <ZviewGstInfo />
                },
                {
                    path: ':id/view-notices',
                    element: <ViewNotices />
                },
                {
                    path: 'userapproval',
                    element: <Userapproval />
                },
                {
                    path: 'phpartners',
                    element: <Phpartners />
                },
                {
                    path: 'phdashboard',
                    element: <Phdashboard />
                },
                {
                    path: 'syncmonitoring',
                    element: <Syncmonitoring />
                },
                {
                    path: 'allnotice',
                    element: <Allnotice />
                },
                {
                    path: 'allgstin',
                    element: <Allgstin />
                },
                {
                    path: 'overview',
                    element: <Overview />
                },
                {
                    path: 'suboverview',
                    element: <Suboverview />
                }, {
                    path: 'latestupdates',
                    element: <Latestupdates />
                }, {
                    path: 'profile',
                    element: <UserProfile />
                }
            ]
        },
        {
            path: "privacy-policy",
            element: <PrivacyPolicy />
        },
    ]
}
export default MainRoutes;
