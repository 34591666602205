import * as fs from 'file-saver';
import { Workbook } from 'exceljs';
import { serviceConfig } from '.';

export const downloadServices: any = {
    // latest  notices download
    get_latest_notices: function (page: any, rowsPerPage: any, filterQuery?: any, sorterQuery?: any, searchquery?: any, noticeQuery?: any, setDownloading?: (downloading: boolean) => void) {
        const table_headers = ['Trade name', 'GSTIN', 'Tax Period', 'Due date', 'Description', 'Tax amount', 'Status', 'Notice Date', 'State', 'Ref ID', 'GST Portal Status', 'Case ID', 'Section'];
        const cell_border = Object({ top: { style: 'thin', color: { argb: '0000000' } }, left: { style: 'thin', color: { argb: '0000000' } }, bottom: { style: 'thin', color: { argb: '0000000' } }, right: { style: 'thin', color: { argb: '0000000' } } })
        const cell_font_white = Object({ name: 'Arial', size: 10, bold: true, color: { argb: 'FFFFFFF' }, })
        const cell_font_black = Object({ name: 'Arial', size: 10, color: { argb: '00000000' }, })

        let query: any = {

        }
        if (filterQuery) {

            if (filterQuery.gst_no) {
                query['gst_no'] = filterQuery.gst_no.join(',')
            }
            if (filterQuery.state) {
                query['state'] = filterQuery.state.join(',')
            }
            if (filterQuery.trade_name) {
                query['trade_name'] = filterQuery.trade_name.join(',')
            }
            if (filterQuery.tradename) {
                query['trade_name'] = filterQuery.tradename.join(',')
            }
            if (filterQuery.user_name) {
                query['user_name'] = filterQuery.user_name.join(',')
            }
            if (filterQuery.tax_period && filterQuery.tax_period.length > 0) {
                query['tax_period'] = filterQuery.tax_period.join(',')
            }
            if (filterQuery.arn_nos && filterQuery.arn_nos.length > 0) {
                query['arn_nos'] = filterQuery.arn_nos.join(',')
            }
            if (filterQuery.case_ids && filterQuery.case_ids.length > 0) {
                query['case_ids'] = filterQuery.case_ids.join(',')
            }
            if (filterQuery.section && filterQuery.section.length > 0) {
                query['section'] = filterQuery.section.join(',')
            }
            if (filterQuery.notice_status && filterQuery.notice_status.length > 0) {
                query['notice_status'] = filterQuery.notice_status.join(',')
            }
            if (filterQuery.due_day && filterQuery.due_day.length > 0) {
                query['days'] = filterQuery.due_day.join(',')
            }
            if (filterQuery.due_from_date && filterQuery.due_to_date) {
                query['notice_start_due_date'] = filterQuery.due_from_date
                query['notice_end_due_date'] = filterQuery.due_to_date
            }
            if (filterQuery.notice_from_date && filterQuery.notice_to_date) {
                query['notice_start_issue_date'] = filterQuery.notice_from_date
                query['notice_end_issue_date'] = filterQuery.notice_to_date
            }
            if (filterQuery.amount_from && filterQuery.amount_oper) {
                query['tax_amount'] = filterQuery.amount_from
                if (filterQuery.amount_oper) {
                    query['tax_amount_op'] = filterQuery.amount_oper
                }
            }

            query['master_type'] = 'Additional'
        } else {
            query['master_type'] = 'Additional'
        }

        if (noticeQuery?.notice_status) {
            query['notice_status'] = noticeQuery.notice_status
        }
        if (noticeQuery?.is_over_due) {
            query['is_over_due'] = true
        }

        if (sorterQuery && sorterQuery.columnKey) {
            query['sort'] = `${sorterQuery.columnKey},${sorterQuery.order === "ascend" ? "asc" : "desc"}`
        }

        if (searchquery) {
            query['search'] = searchquery
        }

        serviceConfig.get("get_latest_notices", true, query || null, null).then((data: any) => {
            console.log(data, 'Gst list')
            const workbook = new Workbook();
            const fileName = 'gst_additional_notice_details_' + new Date().toLocaleDateString();
            const worksheet = workbook.addWorksheet('Notice Details')

            const userData = JSON.parse(localStorage.getItem('userData') || '{}')

            const label_headers = worksheet.addRow(['User Name', 'Email', 'Contact Number', 'Download at', 'Report by'])
            label_headers.eachCell((cell, number) => {
                cell.fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: '6495ED' },
                    bgColor: { argb: '6495ED' }
                };
                cell.font = cell_font_white
                cell.border = cell_border
                worksheet.getColumn(number).width = 20;
            })
            const label_values = worksheet.addRow([userData.user_name, userData.email, userData.contact_number, new Date().toLocaleDateString() + ' ' + new Date().toLocaleTimeString(), 'Zentax Clinic'])
            label_values.eachCell((cell, number) => {
                cell.border = cell_border
                cell.font = cell_font_black
            })

            const header = worksheet.addRow(table_headers)
            header.eachCell((cell, number) => {
                cell.fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: '6495ED' },
                    bgColor: { argb: '6495ED' }
                };
                cell.font = cell_font_white
                cell.border = cell_border
                worksheet.getColumn(number).width = 20;
            })

            data.data.results.forEach((notice: any) => {
                var cell_number = 1
                const cellvalues = [
                    notice.gst.trade_name ? notice.gst.trade_name : '--',
                    notice.gst.gst_no ? notice.gst.gst_no : '--',
                    notice.tax_period ? notice.tax_period : '--',
                    notice.due_date ? notice.due_date : '--',
                    notice.case_data.case_description[notice.case_data.case_description.length - 1] ? notice.case_data.case_description[notice.case_data.case_description.length - 1] : '--',
                    notice.tax_amount ? notice.tax_amount : '--',
                    notice.notice_status ? notice.notice_status : '--',
                    notice.issue_date ? notice.issue_date : '--',
                    notice.gst.state ? notice.gst.state : '--',
                    notice.other_details.refId ? notice.other_details.refId : '--',
                    notice.case_data.portal_status ? notice.case_data.portal_status : '--',
                    notice.case_data.case_arn_no ? notice.case_data.case_arn_no : '--',
                    notice.section ? notice.section : '--',
                ]
                const row = worksheet.addRow(cellvalues);
                table_headers.forEach(() => {
                    var cellData = row.getCell(cell_number)
                    cellData.border = cell_border
                    cellData.font = cell_font_black
                    cell_number++
                })
            })

            const note = worksheet.addRow(['Note', 'Login to solutions.zentaxclinic.com and get solutions to Notices or Expert opinions within 48 hours'])
            note.eachCell((cell, number) => {
                if (number === 1) {
                    cell.fill = {
                        type: 'pattern',
                        pattern: 'solid',
                        fgColor: { argb: '6495ED' },
                        bgColor: { argb: '6495ED' }
                    };
                    cell.font = cell_font_white
                    cell.border = cell_border
                    worksheet.getColumn(number).width = 20;
                } else {
                    cell.border = cell_border
                    cell.font = cell_font_black
                }
            })


            workbook.xlsx.writeBuffer().then((data: any) => {
                const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
                fs.saveAs(blob, fileName + '.xlsx');
                setDownloading && setDownloading(false);
            });
        }, (err: any) => {
            console.log(err)
            setDownloading && setDownloading(false);
        })
    },
    // ....

    // additional notices download
    get_additinal_notices: function (page: any, rowsPerPage: any, filterQuery?: any, sorterQuery?: any, searchquery?: any, noticeQuery?: any, setDownloading?: (downloading: boolean) => void) {
        const table_headers = ['Trade name', 'GSTIN', 'Tax Period', 'Due date', 'Description', 'Tax amount', 'Status', 'Notice Date', 'State', 'Ref ID', 'GST Portal Status', 'Case ID', 'Section'];
        const cell_border = Object({ top: { style: 'thin', color: { argb: '0000000' } }, left: { style: 'thin', color: { argb: '0000000' } }, bottom: { style: 'thin', color: { argb: '0000000' } }, right: { style: 'thin', color: { argb: '0000000' } } })
        const cell_font_white = Object({ name: 'Arial', size: 10, bold: true, color: { argb: 'FFFFFFF' }, })
        const cell_font_black = Object({ name: 'Arial', size: 10, color: { argb: '00000000' }, })

        let query: any = {

        }
        if (filterQuery) {

            if (filterQuery.gst_no) {
                query['gst_no'] = filterQuery.gst_no.join(',')
            }
            if (filterQuery.state) {
                query['state'] = filterQuery.state.join(',')
            }
            if (filterQuery.trade_name) {
                query['trade_name'] = filterQuery.trade_name.join(',')
            }
            if (filterQuery.tradename) {
                query['trade_name'] = filterQuery.tradename.join(',')
            }
            if (filterQuery.user_name) {
                query['user_name'] = filterQuery.user_name.join(',')
            }
            if (filterQuery.tax_period && filterQuery.tax_period.length > 0) {
                query['tax_period'] = filterQuery.tax_period.join(',')
            }
            if (filterQuery.arn_nos && filterQuery.arn_nos.length > 0) {
                query['arn_nos'] = filterQuery.arn_nos.join(',')
            }
            if (filterQuery.case_ids && filterQuery.case_ids.length > 0) {
                query['case_ids'] = filterQuery.case_ids.join(',')
            }
            if (filterQuery.section && filterQuery.section.length > 0) {
                query['section'] = filterQuery.section.join(',')
            }
            if (filterQuery.notice_status && filterQuery.notice_status.length > 0) {
                query['notice_status'] = filterQuery.notice_status.join(',')
            }
            if (filterQuery.due_day && filterQuery.due_day.length > 0) {
                query['days'] = filterQuery.due_day.join(',')
            }
            if (filterQuery.due_from_date && filterQuery.due_to_date) {
                query['notice_start_due_date'] = filterQuery.due_from_date
                query['notice_end_due_date'] = filterQuery.due_to_date
            }
            if (filterQuery.notice_from_date && filterQuery.notice_to_date) {
                query['notice_start_issue_date'] = filterQuery.notice_from_date
                query['notice_end_issue_date'] = filterQuery.notice_to_date
            }
            if (filterQuery.amount_from && filterQuery.amount_oper) {
                query['tax_amount'] = filterQuery.amount_from
                if (filterQuery.amount_oper) {
                    query['tax_amount_op'] = filterQuery.amount_oper
                }
            }

            query['master_type'] = 'Additional'
        } else {
            query['master_type'] = 'Additional'
        }

        if (noticeQuery?.notice_status) {
            query['notice_status'] = noticeQuery.notice_status
        }
        if (noticeQuery?.is_over_due) {
            query['is_over_due'] = true
        }
        if (noticeQuery?.gst_case_id) {
            query['gst_case_id'] = noticeQuery.gst_case_id
        }
        if (sorterQuery && sorterQuery.columnKey) {
            query['sort'] = `${sorterQuery.columnKey},${sorterQuery.order === "ascend" ? "asc" : "desc"}`
        }

        if (searchquery) {
            query['search'] = searchquery
        }

        serviceConfig.get("get_notices", true, query || null, null).then((data: any) => {
            console.log(data, 'Gst list')
            const workbook = new Workbook();
            const fileName = 'gst_additional_notice_details_' + new Date().toLocaleDateString();
            const worksheet = workbook.addWorksheet('Notice Details')

            const userData = JSON.parse(localStorage.getItem('userData') || '{}')

            const label_headers = worksheet.addRow(['User Name', 'Email', 'Contact Number', 'Download at', 'Report by'])
            label_headers.eachCell((cell, number) => {
                cell.fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: '6495ED' },
                    bgColor: { argb: '6495ED' }
                };
                cell.font = cell_font_white
                cell.border = cell_border
                worksheet.getColumn(number).width = 20;
            })
            const label_values = worksheet.addRow([userData.user_name, userData.email, userData.contact_number, new Date().toLocaleDateString() + ' ' + new Date().toLocaleTimeString(), 'Zentax Clinic'])
            label_values.eachCell((cell, number) => {
                cell.border = cell_border
                cell.font = cell_font_black
            })

            const header = worksheet.addRow(table_headers)
            header.eachCell((cell, number) => {
                cell.fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: '6495ED' },
                    bgColor: { argb: '6495ED' }
                };
                cell.font = cell_font_white
                cell.border = cell_border
                worksheet.getColumn(number).width = 20;
            })

            data.forEach((notice: any) => {
                var cell_number = 1
                const cellvalues = [
                    notice.gst.trade_name ? notice.gst.trade_name : '--',
                    notice.gst.gst_no ? notice.gst.gst_no : '--',
                    notice.tax_period ? notice.tax_period : '--',
                    notice.due_date ? notice.due_date : '--',
                    notice.case_data.case_description[notice.case_data.case_description.length - 1] ? notice.case_data.case_description[notice.case_data.case_description.length - 1] : '--',
                    notice.tax_amount ? notice.tax_amount : '--',
                    notice.notice_status ? notice.notice_status : '--',
                    notice.issue_date ? notice.issue_date : '--',
                    notice.gst.state ? notice.gst.state : '--',
                    notice.other_details.refId ? notice.other_details.refId : '--',
                    notice.case_data.portal_status ? notice.case_data.portal_status : '--',
                    notice.case_data.case_arn_no ? notice.case_data.case_arn_no : '--',
                    notice.section ? notice.section : '--',
                ]
                const row = worksheet.addRow(cellvalues);
                table_headers.forEach(() => {
                    var cellData = row.getCell(cell_number)
                    cellData.border = cell_border
                    cellData.font = cell_font_black
                    cell_number++
                })
            })

            const note = worksheet.addRow(['Note', 'Login to solutions.zentaxclinic.com and get solutions to Notices or Expert opinions within 48 hours'])
            note.eachCell((cell, number) => {
                if (number === 1) {
                    cell.fill = {
                        type: 'pattern',
                        pattern: 'solid',
                        fgColor: { argb: '6495ED' },
                        bgColor: { argb: '6495ED' }
                    };
                    cell.font = cell_font_white
                    cell.border = cell_border
                    worksheet.getColumn(number).width = 20;
                } else {
                    cell.border = cell_border
                    cell.font = cell_font_black
                }
            })


            workbook.xlsx.writeBuffer().then((data: any) => {
                const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
                fs.saveAs(blob, fileName + '.xlsx');
                setDownloading && setDownloading(false);
            });
        }, (err: any) => {
            console.log(err)
            setDownloading && setDownloading(false);
        })
    },
    // ....


    // case notices download
    get_case_notices: function (page: any, rowsPerPage: any, filterQuery?: any, sorterQuery?: any, searchquery?: any, noticeQuery?: any, setDownloading?: (downloading: boolean) => void) {
        const table_headers = ['Trade name', 'GSTIN', 'Tax Period', 'Due date', 'Description', 'Tax amount', 'Status', 'Notice Date', 'State', 'Ref ID', 'GST Portal Status', 'Case ID', 'Section'];
        const cell_border = Object({ top: { style: 'thin', color: { argb: '0000000' } }, left: { style: 'thin', color: { argb: '0000000' } }, bottom: { style: 'thin', color: { argb: '0000000' } }, right: { style: 'thin', color: { argb: '0000000' } } })
        const cell_font_white = Object({ name: 'Arial', size: 10, bold: true, color: { argb: 'FFFFFFF' }, })
        const cell_font_black = Object({ name: 'Arial', size: 10, color: { argb: '00000000' }, })

        let query: any = {

        }
        if (filterQuery) {

            if (filterQuery.gst_no) {
                query['gst_no'] = filterQuery.gst_no.join(',')
            }
            if (filterQuery.state) {
                query['state'] = filterQuery.state.join(',')
            }
            if (filterQuery.trade_name) {
                query['trade_name'] = filterQuery.trade_name.join(',')
            }
            if (filterQuery.user_name) {
                query['user_name'] = filterQuery.user_name.join(',')
            }
            if (filterQuery.tax_period && filterQuery.tax_period.length > 0) {
                query['tax_period'] = filterQuery.tax_period.join(',')
            }
            if (filterQuery.arn_nos && filterQuery.arn_nos.length > 0) {
                query['arn_nos'] = filterQuery.arn_nos.join(',')
            }
            if (filterQuery.case_arn_no && filterQuery.case_arn_no.length > 0) {
                query['case_arn_no'] = filterQuery.case_arn_no.join(',')
            }
            if (filterQuery.case_ids && filterQuery.case_ids.length > 0) {
                query['case_ids'] = filterQuery.case_ids.join(',')
            }
            if (filterQuery.section && filterQuery.section.length > 0) {
                query['section'] = filterQuery.section.join(',')
            }
            if (filterQuery.notice_status && filterQuery.notice_status.length > 0) {
                query['notice_status'] = filterQuery.notice_status.join(',')
            }
            if (filterQuery.due_day && filterQuery.due_day.length > 0) {
                query['days'] = filterQuery.due_day.join(',')
            }
            if (filterQuery.due_from_date && filterQuery.due_to_date) {
                query['notice_start_due_date'] = filterQuery.due_from_date
                query['notice_end_due_date'] = filterQuery.due_to_date
            }
            if (filterQuery.notice_from_date && filterQuery.notice_to_date) {
                query['notice_start_issue_date'] = filterQuery.notice_from_date
                query['notice_end_issue_date'] = filterQuery.notice_to_date
            }
            if (filterQuery.amount_from && filterQuery.amount_oper) {
                query['tax_amount'] = filterQuery.amount_from
                if (filterQuery.amount_oper) {
                    query['tax_amount_op'] = filterQuery.amount_oper
                }
            }

            query['master_type'] = 'Additional'
        } else {
            query['master_type'] = 'Additional'
        }

        if (noticeQuery?.notice_status) {
            query['notice_status'] = noticeQuery.notice_status
        }
        if (noticeQuery?.is_over_due) {
            query['is_over_due'] = true
        }
        if (noticeQuery?.gst_case_id) {
            query['gst_case_id'] = noticeQuery.gst_case_id
        }

        if (sorterQuery && sorterQuery.columnKey) {
            query['sort'] = `${sorterQuery.columnKey},${sorterQuery.order === "ascend" ? "asc" : "desc"}`
        }

        if (searchquery) {
            query['search'] = searchquery
        }

        serviceConfig.get("get_notices", true, query || null, null).then((data: any) => {
            console.log(data, 'Gst list')
            const workbook = new Workbook();
            const fileName = 'gst_additional_notice_details_' + new Date().toLocaleDateString();
            const worksheet = workbook.addWorksheet('Notice Details')

            const userData = JSON.parse(localStorage.getItem('userData') || '{}')

            const label_headers = worksheet.addRow(['User Name', 'Email', 'Contact Number', 'Download at', 'Report by'])
            label_headers.eachCell((cell, number) => {
                cell.fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: '6495ED' },
                    bgColor: { argb: '6495ED' }
                };
                cell.font = cell_font_white
                cell.border = cell_border
                worksheet.getColumn(number).width = 20;
            })
            const label_values = worksheet.addRow([userData.user_name, userData.email, userData.contact_number, new Date().toLocaleDateString() + ' ' + new Date().toLocaleTimeString(), 'Zentax Clinic'])
            label_values.eachCell((cell, number) => {
                cell.border = cell_border
                cell.font = cell_font_black
            })

            const header = worksheet.addRow(table_headers)
            header.eachCell((cell, number) => {
                cell.fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: '6495ED' },
                    bgColor: { argb: '6495ED' }
                };
                cell.font = cell_font_white
                cell.border = cell_border
                worksheet.getColumn(number).width = 20;
            })

            data.forEach((notice: any) => {
                var cell_number = 1
                const cellvalues = [
                    notice.gst.trade_name ? notice.gst.trade_name : '--',
                    notice.gst.gst_no ? notice.gst.gst_no : '--',
                    notice.tax_period ? notice.tax_period : '--',
                    notice.due_date ? notice.due_date : '--',
                    notice.case_data.case_description[notice.case_data.case_description.length - 1] ? notice.case_data.case_description[notice.case_data.case_description.length - 1] : '--',
                    notice.tax_amount ? notice.tax_amount : '--',
                    notice.notice_status ? notice.notice_status : '--',
                    notice.issue_date ? notice.issue_date : '--',
                    notice.gst.state ? notice.gst.state : '--',
                    notice.other_details.refId ? notice.other_details.refId : '--',
                    notice.case_data.portal_status ? notice.case_data.portal_status : '--',
                    notice.case_data.case_arn_no ? notice.case_data.case_arn_no : '--',
                    notice.section ? notice.section : '--',
                ]
                const row = worksheet.addRow(cellvalues);
                table_headers.forEach(() => {
                    var cellData = row.getCell(cell_number)
                    cellData.border = cell_border
                    cellData.font = cell_font_black
                    cell_number++
                })
            })

            const note = worksheet.addRow(['Note', 'Login to solutions.zentaxclinic.com and get solutions to Notices or Expert opinions within 48 hours'])
            note.eachCell((cell, number) => {
                if (number === 1) {
                    cell.fill = {
                        type: 'pattern',
                        pattern: 'solid',
                        fgColor: { argb: '6495ED' },
                        bgColor: { argb: '6495ED' }
                    };
                    cell.font = cell_font_white
                    cell.border = cell_border
                    worksheet.getColumn(number).width = 20;
                } else {
                    cell.border = cell_border
                    cell.font = cell_font_black
                }
            })


            workbook.xlsx.writeBuffer().then((data: any) => {
                const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
                fs.saveAs(blob, fileName + '.xlsx');
                setDownloading && setDownloading(false);
            });
        }, (err: any) => {
            console.log(err)
            setDownloading && setDownloading(false);
        })
    },
    // ....

    // gstsummary view download
    get_group_notices: function (page: any, rowsPerPage: any, filterQuery?: any, sorterQuery?: any, searchquery?: any, manageactive?: any, setDownloading?: (downloading: boolean) => void) {
        const table_headers = ['Company Name', 'GSTIN', 'Total Notices', 'Action to be taken', 'Overdue', 'User Name', 'Password', 'Demand amount O/S'];
        const cell_border = Object({ top: { style: 'thin', color: { argb: '0000000' } }, left: { style: 'thin', color: { argb: '0000000' } }, bottom: { style: 'thin', color: { argb: '0000000' } }, right: { style: 'thin', color: { argb: '0000000' } } })
        const cell_font_white = Object({ name: 'Arial', size: 10, bold: true, color: { argb: 'FFFFFFF' }, })
        const cell_font_black = Object({ name: 'Arial', size: 10, color: { argb: '00000000' }, })

        let query: any = {

        }
        if (filterQuery) {
            if (filterQuery.gst_no) {
                query['gst_no'] = filterQuery.gst_no.join(',')
            }
            if (filterQuery.state) {
                query['state'] = filterQuery.state.join(',')
            }
            if (filterQuery.trade_name) {
                query['trade_name'] = filterQuery.trade_name.join(',')
            }
            if (filterQuery.tradename) {
                query['trade_name'] = filterQuery.tradename.join(',')
            }
            if (filterQuery.user_name) {
                query['user_name'] = filterQuery.user_name.join(',')
            }
            if (filterQuery.tax_period && filterQuery.tax_period.length > 0) {
                query['tax_period'] = filterQuery.tax_period.join(',')
            }
            if (filterQuery.arn_nos && filterQuery.arn_nos.length > 0) {
                query['arn_nos'] = filterQuery.arn_nos.join(',')
            }
            if (filterQuery.case_ids && filterQuery.case_ids.length > 0) {
                query['case_ids'] = filterQuery.case_ids.join(',')
            }
            if (filterQuery.section && filterQuery.section.length > 0) {
                query['section'] = filterQuery.section.join(',')
            }
            if (filterQuery.notice_status && filterQuery.notice_status.length > 0) {
                query['notice_status'] = filterQuery.notice_status.join(',')
            }
            if (filterQuery.due_day && filterQuery.due_day.length > 0) {
                query['days'] = filterQuery.due_day.join(',')
            }
            if (filterQuery.due_from_date && filterQuery.due_to_date) {
                query['notice_start_due_date'] = filterQuery.due_from_date
                query['notice_end_due_date'] = filterQuery.due_to_date
            }
            if (filterQuery.notice_from_date && filterQuery.notice_to_date) {
                query['notice_start_issue_date'] = filterQuery.notice_from_date
                query['notice_end_issue_date'] = filterQuery.notice_to_date
            }
            if (filterQuery.amount_from && filterQuery.amount_oper) {
                query['tax_amount'] = filterQuery.amount_from
                if (filterQuery.amount_oper) {
                    query['tax_amount_op'] = filterQuery.amount_oper
                }
            }
        }

        if (sorterQuery && sorterQuery.columnKey) {
            query['sort'] = `${sorterQuery.columnKey},${sorterQuery.order === "ascend" ? "asc" : "desc"}`
        }

        if (searchquery) {
            query['search'] = searchquery
        }

        if (manageactive) {
            if (manageactive === 'Active') {
                query['active'] = true
            } else if (manageactive === 'Incorrect') {
                query['active'] = false
            }
        }

        const formatter = new Intl.NumberFormat('en-IN')

        serviceConfig.get("get_group_notices", true, query || null, null).then((data: any) => {
            console.log(data, 'Gst list')
            const workbook = new Workbook();
            const fileName = 'gst_summary_notice_details_' + new Date().toLocaleDateString();
            const worksheet = workbook.addWorksheet('Notice Details')

            const userData = JSON.parse(localStorage.getItem('userData') || '{}')

            const label_headers = worksheet.addRow(['User Name', 'Email', 'Contact Number', 'Download at', 'Report by'])
            label_headers.eachCell((cell, number) => {
                cell.fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: '6495ED' },
                    bgColor: { argb: '6495ED' }
                };
                cell.font = cell_font_white
                cell.border = cell_border
                worksheet.getColumn(number).width = 20;
            })
            const label_values = worksheet.addRow([userData.user_name, userData.email, userData.contact_number, new Date().toLocaleDateString() + ' ' + new Date().toLocaleTimeString(), 'Zentax Clinic'])
            label_values.eachCell((cell, number) => {
                cell.border = cell_border
                cell.font = cell_font_black
            })

            const header = worksheet.addRow(table_headers)
            header.eachCell((cell, number) => {
                cell.fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: '6495ED' },
                    bgColor: { argb: '6495ED' }
                };
                cell.font = cell_font_white
                cell.border = cell_border
                worksheet.getColumn(number).width = 20;
            })

            data.forEach((notice: any) => {
                var cell_number = 1
                const cellvalues = [
                    notice.gst.trade_name ? notice.gst.trade_name : '--',
                    notice.gst.gst_no ? notice.gst.gst_no : '--',
                    notice.total_notices ? notice.total_notices : '--',
                    // notice.action_taken ? notice.action_taken : '--',
                    notice.action_to_be_taken ? notice.action_to_be_taken : '--',
                    notice.overdue ? notice.overdue : '--',
                    notice.gst.user_name ? notice.gst.user_name : '--',
                    notice.gst.password ? notice.gst.password : '--',
                    // notice.no_action_required ? notice.no_action_required : '--',
                    notice.demand_amount ? formatter.format(Number(notice.demand_amount.toFixed(2))) : '--'
                ]
                const row = worksheet.addRow(cellvalues);
                table_headers.forEach(() => {
                    var cellData = row.getCell(cell_number)
                    cellData.border = cell_border
                    cellData.font = cell_font_black
                    cell_number++
                })
            })

            const note = worksheet.addRow(['Note', 'Login to solutions.zentaxclinic.com and get solutions to Notices or Expert opinions within 48 hours'])
            note.eachCell((cell, number) => {
                if (number === 1) {
                    cell.fill = {
                        type: 'pattern',
                        pattern: 'solid',
                        fgColor: { argb: '6495ED' },
                        bgColor: { argb: '6495ED' }
                    };
                    cell.font = cell_font_white
                    cell.border = cell_border
                    worksheet.getColumn(number).width = 20;
                } else {
                    cell.border = cell_border
                    cell.font = cell_font_black
                }
            })


            workbook.xlsx.writeBuffer().then((data: any) => {
                const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
                fs.saveAs(blob, fileName + '.xlsx');
                setDownloading && setDownloading(false);
            });
        }, (err: any) => {
            console.log(err)
            setDownloading && setDownloading(false);
        })
    },
    // ....

    // notice and orders list notices download
    get_notices_orders: function (page: any, rowsPerPage: any, filterQuery?: any, sorterQuery?: any, searchquery?: any, setDownloading?: (downloading: boolean) => void) {
        const table_headers = ['Trade name', 'Notice/Order Id', 'Type', 'Notice/Order Description', 'Date of Issuance', 'Due Date'];
        const cell_border = Object({ top: { style: 'thin', color: { argb: '0000000' } }, left: { style: 'thin', color: { argb: '0000000' } }, bottom: { style: 'thin', color: { argb: '0000000' } }, right: { style: 'thin', color: { argb: '0000000' } } })
        const cell_font_white = Object({ name: 'Arial', size: 10, bold: true, color: { argb: 'FFFFFFF' }, })
        const cell_font_black = Object({ name: 'Arial', size: 10, color: { argb: '00000000' }, })

        let query: any = {

        }
        if (filterQuery) {
            if (filterQuery.gst_no) {
                query['gst_no'] = filterQuery.gst_no.join(',')
            }
            if (filterQuery.state) {
                query['state'] = filterQuery.state.join(',')
            }
            if (filterQuery.trade_name) {
                query['trade_name'] = filterQuery.trade_name.join(',')
            }
            if (filterQuery.tradename) {
                query['trade_name'] = filterQuery.tradename.join(',')
            }
            if (filterQuery.user_name) {
                query['user_name'] = filterQuery.user_name.join(',')
            }
            if (filterQuery.tax_period && filterQuery.tax_period.length > 0) {
                query['tax_period'] = filterQuery.tax_period.join(',')
            }
            if (filterQuery.arn_nos && filterQuery.arn_nos.length > 0) {
                query['arn_nos'] = filterQuery.arn_nos.join(',')
            }
            if (filterQuery.case_ids && filterQuery.case_ids.length > 0) {
                query['case_ids'] = filterQuery.case_ids.join(',')
            }
            if (filterQuery.section && filterQuery.section.length > 0) {
                query['section'] = filterQuery.section.join(',')
            }
            if (filterQuery.notice_status && filterQuery.notice_status.length > 0) {
                query['notice_status'] = filterQuery.notice_status.join(',')
            }
            if (filterQuery.due_day && filterQuery.due_day.length > 0) {
                query['days'] = filterQuery.due_day.join(',')
            }
            if (filterQuery.due_from_date && filterQuery.due_to_date) {
                query['notice_start_due_date'] = filterQuery.due_from_date
                query['notice_end_due_date'] = filterQuery.due_to_date
            }
            if (filterQuery.notice_from_date && filterQuery.notice_to_date) {
                query['notice_start_issue_date'] = filterQuery.notice_from_date
                query['notice_end_issue_date'] = filterQuery.notice_to_date
            }
            if (filterQuery.amount_from && filterQuery.amount_oper) {
                query['tax_amount'] = filterQuery.amount_from
                if (filterQuery.amount_oper) {
                    query['tax_amount_op'] = filterQuery.amount_oper
                }
            }
        }

        if (sorterQuery && sorterQuery.columnKey) {
            query['sort'] = `${sorterQuery.columnKey},${sorterQuery.order === "ascend" ? "asc" : "desc"}`
        }

        if (searchquery) {
            query['search'] = searchquery
        }

        serviceConfig.get("get_notices_orders", true, query || null, null).then((data: any) => {
            console.log(data, 'Gst list')
            const workbook = new Workbook();
            const fileName = 'gst_notice&orders_details_' + new Date().toLocaleDateString();
            const worksheet = workbook.addWorksheet('Notice Details')

            const userData = JSON.parse(localStorage.getItem('userData') || '{}')

            const label_headers = worksheet.addRow(['User Name', 'Email', 'Contact Number', 'Download at', 'Report by'])
            label_headers.eachCell((cell, number) => {
                cell.fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: '6495ED' },
                    bgColor: { argb: '6495ED' }
                };
                cell.font = cell_font_white
                cell.border = cell_border
                worksheet.getColumn(number).width = 20;
            })
            const label_values = worksheet.addRow([userData.user_name, userData.email, userData.contact_number, new Date().toLocaleDateString() + ' ' + new Date().toLocaleTimeString(), 'Zentax Clinic'])
            label_values.eachCell((cell, number) => {
                cell.border = cell_border
                cell.font = cell_font_black
            })

            const header = worksheet.addRow(table_headers)
            header.eachCell((cell, number) => {
                cell.fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: '6495ED' },
                    bgColor: { argb: '6495ED' }
                };
                cell.font = cell_font_white
                cell.border = cell_border
                worksheet.getColumn(number).width = 20;
            })

            data.forEach((notice: any) => {
                var cell_number = 1
                const cellvalues = [
                    notice.gst.trade_name ? notice.gst.trade_name : '--',
                    notice.notice_order_id ? notice.notice_order_id : '--',
                    notice.type ? notice.type : '--',
                    notice.description ? notice.description : '--',
                    notice.issue_date ? notice.issue_date : '--',
                    notice.due_date ? notice.due_date : '--'
                ]
                const row = worksheet.addRow(cellvalues);
                table_headers.forEach(() => {
                    var cellData = row.getCell(cell_number)
                    cellData.border = cell_border
                    cellData.font = cell_font_black
                    cell_number++
                })
            })

            const note = worksheet.addRow(['Note', 'Login to solutions.zentaxclinic.com and get solutions to Notices or Expert opinions within 48 hours'])
            note.eachCell((cell, number) => {
                if (number === 1) {
                    cell.fill = {
                        type: 'pattern',
                        pattern: 'solid',
                        fgColor: { argb: '6495ED' },
                        bgColor: { argb: '6495ED' }
                    };
                    cell.font = cell_font_white
                    cell.border = cell_border
                    worksheet.getColumn(number).width = 20;
                } else {
                    cell.border = cell_border
                    cell.font = cell_font_black
                }
            })


            workbook.xlsx.writeBuffer().then((data: any) => {
                const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
                fs.saveAs(blob, fileName + '.xlsx');
                setDownloading && setDownloading(false);

            });
        }, (err: any) => {
            console.log(err)
            setDownloading && setDownloading(false);

        })
    },
    // ....

    // manual list notices download
    get_manual_notices: function (page: any, rowsPerPage: any, filterQuery?: any, sorterQuery?: any, searchquery?: any, setDownloading?: (downloading: boolean) => void) {
        const table_headers = ['Trade name', 'GSTIN', 'Tax Period', 'Due date', 'Description', 'Tax amount', 'Status', 'Notice Date', 'State', 'Ref ID', 'GST Portal Status', 'Case ID', 'Section'];
        const cell_border = Object({ top: { style: 'thin', color: { argb: '0000000' } }, left: { style: 'thin', color: { argb: '0000000' } }, bottom: { style: 'thin', color: { argb: '0000000' } }, right: { style: 'thin', color: { argb: '0000000' } } })
        const cell_font_white = Object({ name: 'Arial', size: 10, bold: true, color: { argb: 'FFFFFFF' }, })
        const cell_font_black = Object({ name: 'Arial', size: 10, color: { argb: '00000000' }, })

        let query: any = {

        }
        if (filterQuery) {
            if (filterQuery.gst_no) {
                query['gst_no'] = filterQuery.gst_no.join(',')
            }
            if (filterQuery.state) {
                query['state'] = filterQuery.state.join(',')
            }
            if (filterQuery.trade_name) {
                query['trade_name'] = filterQuery.trade_name.join(',')
            }
            if (filterQuery.tradename) {
                query['trade_name'] = filterQuery.tradename.join(',')
            }
            if (filterQuery.user_name) {
                query['user_name'] = filterQuery.user_name.join(',')
            }
            if (filterQuery.tax_period && filterQuery.tax_period.length > 0) {
                query['tax_period'] = filterQuery.tax_period.join(',')
            }
            if (filterQuery.arn_nos && filterQuery.arn_nos.length > 0) {
                query['arn_nos'] = filterQuery.arn_nos.join(',')
            }
            if (filterQuery.case_ids && filterQuery.case_ids.length > 0) {
                query['case_ids'] = filterQuery.case_ids.join(',')
            }
            if (filterQuery.section && filterQuery.section.length > 0) {
                query['section'] = filterQuery.section.join(',')
            }
            if (filterQuery.notice_status && filterQuery.notice_status.length > 0) {
                query['notice_status'] = filterQuery.notice_status.join(',')
            }
            if (filterQuery.due_day && filterQuery.due_day.length > 0) {
                query['days'] = filterQuery.due_day.join(',')
            }
            if (filterQuery.due_from_date && filterQuery.due_to_date) {
                query['notice_start_due_date'] = filterQuery.due_from_date
                query['notice_end_due_date'] = filterQuery.due_to_date
            }
            if (filterQuery.notice_from_date && filterQuery.notice_to_date) {
                query['notice_start_issue_date'] = filterQuery.notice_from_date
                query['notice_end_issue_date'] = filterQuery.notice_to_date
            }
            if (filterQuery.amount_from && filterQuery.amount_oper) {
                query['tax_amount'] = filterQuery.amount_from
                if (filterQuery.amount_oper) {
                    query['tax_amount_op'] = filterQuery.amount_oper
                }
            }
            query['master_type'] = 'Manual'
        }
        else {
            query['master_type'] = 'Manual'
        }

        if (sorterQuery && sorterQuery.columnKey) {
            query['sort'] = `${sorterQuery.columnKey},${sorterQuery.order === "ascend" ? "asc" : "desc"}`
        }

        if (searchquery) {
            query['search'] = searchquery
        }

        serviceConfig.get("get_notices", true, query || null, null).then((data: any) => {
            console.log(data, 'Gst list')
            const workbook = new Workbook();
            const fileName = 'gst_manual_notice_details_' + new Date().toLocaleDateString();
            const worksheet = workbook.addWorksheet('Notice Details')

            const userData = JSON.parse(localStorage.getItem('userData') || '{}')

            const label_headers = worksheet.addRow(['User Name', 'Email', 'Contact Number', 'Download at', 'Report by'])
            label_headers.eachCell((cell, number) => {
                cell.fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: '6495ED' },
                    bgColor: { argb: '6495ED' }
                };
                cell.font = cell_font_white
                cell.border = cell_border
                worksheet.getColumn(number).width = 20;
            })
            const label_values = worksheet.addRow([userData.user_name, userData.email, userData.contact_number, new Date().toLocaleDateString() + ' ' + new Date().toLocaleTimeString(), 'Zentax Clinic'])
            label_values.eachCell((cell, number) => {
                cell.border = cell_border
                cell.font = cell_font_black
            })

            const header = worksheet.addRow(table_headers)
            header.eachCell((cell, number) => {
                cell.fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: '6495ED' },
                    bgColor: { argb: '6495ED' }
                };
                cell.font = cell_font_white
                cell.border = cell_border
                worksheet.getColumn(number).width = 20;
            })

            data.forEach((notice: any) => {
                var cell_number = 1
                const cellvalues = [
                    notice.gst.trade_name ? notice.gst.trade_name : '--',
                    notice.gst.gst_no ? notice.gst.gst_no : '--',
                    notice.tax_period ? notice.tax_period : '--',
                    notice.due_date ? notice.due_date : '--',
                    notice.case_data.case_description[notice.case_data.case_description.length - 1] ? notice.case_data.case_description[notice.case_data.case_description.length - 1] : '--',
                    notice.tax_amount ? notice.tax_amount : '--',
                    notice.notice_status ? notice.notice_status : '--',
                    notice.issue_date ? notice.issue_date : '--',
                    notice.gst.state ? notice.gst.state : '--',
                    notice.case_data.case_ref_id[notice.case_data.case_ref_id.length - 1] ? notice.case_data.case_ref_id[notice.case_data.case_ref_id.length - 1] : '--',
                    notice.case_data.portal_status ? notice.case_data.portal_status : '--',
                    notice.case_data.case_arn_no ? notice.case_data.case_arn_no : '--',
                    notice.section ? notice.section : '--',
                ]
                const row = worksheet.addRow(cellvalues);
                table_headers.forEach(() => {
                    var cellData = row.getCell(cell_number)
                    cellData.border = cell_border
                    cellData.font = cell_font_black
                    cell_number++
                })
            })

            const note = worksheet.addRow(['Note', 'Login to solutions.zentaxclinic.com and get solutions to Notices or Expert opinions within 48 hours'])
            note.eachCell((cell, number) => {
                if (number === 1) {
                    cell.fill = {
                        type: 'pattern',
                        pattern: 'solid',
                        fgColor: { argb: '6495ED' },
                        bgColor: { argb: '6495ED' }
                    };
                    cell.font = cell_font_white
                    cell.border = cell_border
                    worksheet.getColumn(number).width = 20;
                } else {
                    cell.border = cell_border
                    cell.font = cell_font_black
                }
            })


            workbook.xlsx.writeBuffer().then((data: any) => {
                const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
                fs.saveAs(blob, fileName + '.xlsx');
                setDownloading && setDownloading(false);

            });
        }, (err: any) => {
            console.log(err)
            setDownloading && setDownloading(false);

        })
    },
    // ....

    // case summary download
    get_case_summary: function (page: any, rowsPerPage: any, filterQuery?: any, sorterQuery?: any, searchquery?: any, tabactive?: any, setDownloading?: (downloading: boolean) => void) {
        const table_headers = ['Case Id', 'Tax Period', 'Case Status', 'Portal Status', 'Notice Count', 'Latest Notice On', 'Demand amount O/S', 'Remarks'];
        const cell_border = Object({ top: { style: 'thin', color: { argb: '0000000' } }, left: { style: 'thin', color: { argb: '0000000' } }, bottom: { style: 'thin', color: { argb: '0000000' } }, right: { style: 'thin', color: { argb: '0000000' } } })
        const cell_font_white = Object({ name: 'Arial', size: 10, bold: true, color: { argb: 'FFFFFFF' }, })
        const cell_font_black = Object({ name: 'Arial', size: 10, color: { argb: '00000000' }, })

        let query: any = {

        }
        if (filterQuery) {
            if (filterQuery.gst_no) {
                query['gst_no'] = filterQuery.gst_no.join(',')
            }
            if (filterQuery.state) {
                query['state'] = filterQuery.state.join(',')
            }
            if (filterQuery.trade_name) {
                query['trade_name'] = filterQuery.trade_name.join(',')
            }
            if (filterQuery.user_name) {
                query['user_name'] = filterQuery.user_name.join(',')
            }
            if (filterQuery.tax_period && filterQuery.tax_period.length > 0) {
                query['tax_period'] = filterQuery.tax_period.join(',')
            }
            if (filterQuery.arn_nos && filterQuery.arn_nos.length > 0) {
                query['arn_nos'] = filterQuery.arn_nos.join(',')
            }
            if (filterQuery.case_ids && filterQuery.case_ids.length > 0) {
                query['case_ids'] = filterQuery.case_ids.join(',')
            }
            if (filterQuery.section && filterQuery.section.length > 0) {
                query['section'] = filterQuery.section.join(',')
            }
            if (filterQuery.notice_status && filterQuery.notice_status.length > 0) {
                query['notice_status'] = filterQuery.notice_status.join(',')
            }
            if (filterQuery.due_day && filterQuery.due_day.length > 0) {
                query['days'] = filterQuery.due_day.join(',')
            }
            if (filterQuery.due_from_date && filterQuery.due_to_date) {
                query['notice_start_due_date'] = filterQuery.due_from_date
                query['notice_end_due_date'] = filterQuery.due_to_date
            }
            if (filterQuery.notice_from_date && filterQuery.notice_to_date) {
                query['notice_start_issue_date'] = filterQuery.notice_from_date
                query['notice_end_issue_date'] = filterQuery.notice_to_date
            }
            if (filterQuery.amount_from && filterQuery.amount_oper) {
                query['tax_amount'] = filterQuery.amount_from
                if (filterQuery.amount_oper) {
                    query['tax_amount_op'] = filterQuery.amount_oper
                }
            }
        }
        if (tabactive === 'Additional' || tabactive === 'Manual') {
            query['master_type'] = tabactive
        }

        if (sorterQuery && sorterQuery.columnKey) {
            query['sort'] = `${sorterQuery.columnKey},${sorterQuery.order === "ascend" ? "asc" : "desc"}`
        } else {
            query['sort'] = "tax_period,asc"
        }

        if (searchquery) {
            query['search'] = searchquery
        }

        const formatter = new Intl.NumberFormat('en-IN')

        serviceConfig.get("get_cases", true, query || null, null).then((data: any) => {
            console.log(data, 'Gst list')
            const workbook = new Workbook();
            const fileName = 'gst_case_summary_details_' + new Date().toLocaleDateString();
            const worksheet = workbook.addWorksheet('Notice Details')

            const userData = JSON.parse(localStorage.getItem('userData') || '{}')

            const label_headers = worksheet.addRow(['User Name', 'Email', 'Contact Number', 'Download at', 'Report by'])
            label_headers.eachCell((cell, number) => {
                cell.fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: '6495ED' },
                    bgColor: { argb: '6495ED' }
                };
                cell.font = cell_font_white
                cell.border = cell_border
                worksheet.getColumn(number).width = 20;
            })
            const label_values = worksheet.addRow([userData.user_name, userData.email, userData.contact_number, new Date().toLocaleDateString() + ' ' + new Date().toLocaleTimeString(), 'Zentax Clinic'])
            label_values.eachCell((cell, number) => {
                cell.border = cell_border
                cell.font = cell_font_black
            })

            const header = worksheet.addRow(table_headers)
            header.eachCell((cell, number) => {
                cell.fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: '6495ED' },
                    bgColor: { argb: '6495ED' }
                };
                cell.font = cell_font_white
                cell.border = cell_border
                worksheet.getColumn(number).width = 20;
            })

            data.forEach((notice: any) => {
                var cell_number = 1
                const cellvalues = [
                    notice?.case_arn_no ?? '--',
                    notice?.tax_period ?? '--',
                    notice?.case_status ?? '--',
                    notice?.portal_status ?? '--',
                    notice?.notice_count ?? '--',
                    notice?.latest_date ?? '--',
                    notice.demand_amount ? formatter.format(Number(notice.demand_amount.toFixed(2))) : '--',
                    notice?.latest_date ?? '--',
                    notice?.remarks ?? '--',
                ]
                const row = worksheet.addRow(cellvalues);
                table_headers.forEach(() => {
                    var cellData = row.getCell(cell_number)
                    cellData.border = cell_border
                    cellData.font = cell_font_black
                    cell_number++
                })
            })

            const note = worksheet.addRow(['Note', 'Login to solutions.zentaxclinic.com and get solutions to Notices or Expert opinions within 48 hours'])
            note.eachCell((cell, number) => {
                if (number === 1) {
                    cell.fill = {
                        type: 'pattern',
                        pattern: 'solid',
                        fgColor: { argb: '6495ED' },
                        bgColor: { argb: '6495ED' }
                    };
                    cell.font = cell_font_white
                    cell.border = cell_border
                    worksheet.getColumn(number).width = 20;
                } else {
                    cell.border = cell_border
                    cell.font = cell_font_black
                }
            })


            workbook.xlsx.writeBuffer().then((data: any) => {
                const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
                fs.saveAs(blob, fileName + '.xlsx');
                setDownloading && setDownloading(false);

            });
        }, (err: any) => {
            console.log(err)
            setDownloading && setDownloading(false);

        })
    },

    get_group_cases: function (page: any, rowsPerPage: any, filterQuery?: any, sorterQuery?: any, searchquery?: any, setDownloading?: (downloading: boolean) => void) {
        const table_headers = ['Company Name', 'GSTIN', 'Total Cases', 'Action Taken', 'Action to be taken', 'No Action required', 'Demand amount O/S'];
        const cell_border = Object({ top: { style: 'thin', color: { argb: '0000000' } }, left: { style: 'thin', color: { argb: '0000000' } }, bottom: { style: 'thin', color: { argb: '0000000' } }, right: { style: 'thin', color: { argb: '0000000' } } })
        const cell_font_white = Object({ name: 'Arial', size: 10, bold: true, color: { argb: 'FFFFFFF' }, })
        const cell_font_black = Object({ name: 'Arial', size: 10, color: { argb: '00000000' }, })

        let query: any = {

        }
        if (filterQuery) {
            if (filterQuery.gst_no) {
                query['gst_no'] = filterQuery.gst_no.join(',')
            }
            if (filterQuery.state) {
                query['state'] = filterQuery.state.join(',')
            }
            if (filterQuery.trade_name) {
                query['trade_name'] = filterQuery.trade_name.join(',')
            }
            if (filterQuery.user_name) {
                query['user_name'] = filterQuery.user_name.join(',')
            }
            if (filterQuery.tax_period && filterQuery.tax_period.length > 0) {
                query['tax_period'] = filterQuery.tax_period.join(',')
            }
            if (filterQuery.arn_nos && filterQuery.arn_nos.length > 0) {
                query['arn_nos'] = filterQuery.arn_nos.join(',')
            }
            if (filterQuery.case_ids && filterQuery.case_ids.length > 0) {
                query['case_ids'] = filterQuery.case_ids.join(',')
            }
            if (filterQuery.section && filterQuery.section.length > 0) {
                query['section'] = filterQuery.section.join(',')
            }
            if (filterQuery.notice_status && filterQuery.notice_status.length > 0) {
                query['notice_status'] = filterQuery.notice_status.join(',')
            }
            if (filterQuery.due_day && filterQuery.due_day.length > 0) {
                query['days'] = filterQuery.due_day.join(',')
            }
            if (filterQuery.due_from_date && filterQuery.due_to_date) {
                query['notice_start_due_date'] = filterQuery.due_from_date
                query['notice_end_due_date'] = filterQuery.due_to_date
            }
            if (filterQuery.notice_from_date && filterQuery.notice_to_date) {
                query['notice_start_issue_date'] = filterQuery.notice_from_date
                query['notice_end_issue_date'] = filterQuery.notice_to_date
            }
            if (filterQuery.amount_from && filterQuery.amount_oper) {
                query['tax_amount'] = filterQuery.amount_from
                if (filterQuery.amount_oper) {
                    query['tax_amount_op'] = filterQuery.amount_oper
                }
            }
        }

        if (sorterQuery && sorterQuery.columnKey) {
            query['sort'] = `${sorterQuery.columnKey},${sorterQuery.order === "ascend" ? "asc" : "desc"}`
        }

        if (searchquery) {
            query['search'] = searchquery
        }

        const formatter = new Intl.NumberFormat('en-IN')

        serviceConfig.get("get_cases", true, query || null, null).then((data: any) => {
            console.log(data, 'Gst list')
            const workbook = new Workbook();
            const fileName = 'gst_case_summary_details_' + new Date().toLocaleDateString();
            const worksheet = workbook.addWorksheet('Notice Details')

            const userData = JSON.parse(localStorage.getItem('userData') || '{}')

            const label_headers = worksheet.addRow(['User Name', 'Email', 'Contact Number', 'Download at', 'Report by'])
            label_headers.eachCell((cell, number) => {
                cell.fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: '6495ED' },
                    bgColor: { argb: '6495ED' }
                };
                cell.font = cell_font_white
                cell.border = cell_border
                worksheet.getColumn(number).width = 20;
            })
            const label_values = worksheet.addRow([userData.user_name, userData.email, userData.contact_number, new Date().toLocaleDateString() + ' ' + new Date().toLocaleTimeString(), 'Zentax Clinic'])
            label_values.eachCell((cell, number) => {
                cell.border = cell_border
                cell.font = cell_font_black
            })

            const header = worksheet.addRow(table_headers)
            header.eachCell((cell, number) => {
                cell.fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: '6495ED' },
                    bgColor: { argb: '6495ED' }
                };
                cell.font = cell_font_white
                cell.border = cell_border
                worksheet.getColumn(number).width = 20;
            })

            data.forEach((notice: any) => {
                var cell_number = 1
                const cellvalues = [
                    notice.gst.trade_name ? notice.gst.trade_name : '--',
                    notice.gst.gst_no ? notice.gst.gst_no : '--',
                    notice.total_cases ? notice.total_cases : '--',
                    notice.action_taken ? notice.action_taken : '--',
                    notice.action_to_be_taken ? notice.action_to_be_taken : '--',
                    notice.no_action_required ? notice.no_action_required : '--',
                    notice.demand_amount ? formatter.format(Number(notice.demand_amount.toFixed(2))) : '--'
                ]
                const row = worksheet.addRow(cellvalues);
                table_headers.forEach(() => {
                    var cellData = row.getCell(cell_number)
                    cellData.border = cell_border
                    cellData.font = cell_font_black
                    cell_number++
                })
            })

            const note = worksheet.addRow(['Note', 'Login to solutions.zentaxclinic.com and get solutions to Notices or Expert opinions within 48 hours'])
            note.eachCell((cell, number) => {
                if (number === 1) {
                    cell.fill = {
                        type: 'pattern',
                        pattern: 'solid',
                        fgColor: { argb: '6495ED' },
                        bgColor: { argb: '6495ED' }
                    };
                    cell.font = cell_font_white
                    cell.border = cell_border
                    worksheet.getColumn(number).width = 20;
                } else {
                    cell.border = cell_border
                    cell.font = cell_font_black
                }
            })


            workbook.xlsx.writeBuffer().then((data: any) => {
                const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
                fs.saveAs(blob, fileName + '.xlsx');
                setDownloading && setDownloading(false);

            });
        }, (err: any) => {
            console.log(err)
            setDownloading && setDownloading(false);

        })
    },
    // ....

    // due dashboard download
    due_notices: function (page: any, rowsPerPage: any, filterQuery?: any, sorterQuery?: any, searchquery?: any, setDownloading?: (downloading: boolean) => void) {
        const table_headers = ['Due Date', 'Day', 'GSTIN', 'State', 'Trade Name', 'Tax Period', 'Description'];
        const cell_border = Object({ top: { style: 'thin', color: { argb: '0000000' } }, left: { style: 'thin', color: { argb: '0000000' } }, bottom: { style: 'thin', color: { argb: '0000000' } }, right: { style: 'thin', color: { argb: '0000000' } } })
        const cell_font_white = Object({ name: 'Arial', size: 10, bold: true, color: { argb: 'FFFFFFF' }, })
        const cell_font_black = Object({ name: 'Arial', size: 10, color: { argb: '00000000' }, })

        let query: any = {

        }

        if (filterQuery) {
            if (filterQuery.due_from_date && filterQuery.due_to_date) {
                query['notice_start_due_date'] = filterQuery.due_from_date
                query['notice_end_due_date'] = filterQuery.due_to_date
            }

            if (filterQuery.gst_no) {
                query['gst_no'] = filterQuery.gst_no.join(',')
            }
            if (filterQuery.state) {
                query['state'] = filterQuery.state.join(',')
            }
            if (filterQuery.due_day) {
                query['due_day'] = filterQuery.due_day.join(',')
            }
            if (filterQuery.trade_name) {
                query['trade_name'] = filterQuery.trade_name.join(',')
            }
            if (filterQuery.tradename) {
                query['trade_name'] = filterQuery.tradename.join(',')
            }
            if (filterQuery.tax_period && filterQuery.tax_period.length > 0) {
                query['tax_period'] = filterQuery.tax_period.join(',')
            }
            query['is_due'] = true
        }
        else {
            query['is_due'] = true
        }

        if (sorterQuery && sorterQuery.columnKey) {
            query['sort'] = `${sorterQuery.columnKey},${sorterQuery.order === 'ascend' ? 'asc' : 'desc'}`
        }

        if (searchquery) {
            query['search'] = searchquery
        }
        serviceConfig.get("get_notices", true, query || null, null).then((data: any) => {
            console.log(data, 'Gst list')
            const workbook = new Workbook();
            const fileName = 'missed_upcoming_due_' + new Date().toLocaleDateString();
            const worksheet = workbook.addWorksheet('Due Details')

            const userData = JSON.parse(localStorage.getItem('userData') || '{}')

            const label_headers = worksheet.addRow(['User Name', 'Email', 'Contact Number', 'Download at', 'Report by'])
            label_headers.eachCell((cell, number) => {
                cell.fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: '6495ED' },
                    bgColor: { argb: '6495ED' }
                };
                cell.font = cell_font_white
                cell.border = cell_border
                worksheet.getColumn(number).width = 20;
            })
            const label_values = worksheet.addRow([userData.user_name, userData.email, userData.contact_number, new Date().toLocaleDateString() + ' ' + new Date().toLocaleTimeString(), 'Zentax Clinic'])
            label_values.eachCell((cell, number) => {
                cell.border = cell_border
                cell.font = cell_font_black
            })

            const header = worksheet.addRow(table_headers)
            header.eachCell((cell, number) => {
                cell.fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: '6495ED' },
                    bgColor: { argb: '6495ED' }
                };
                cell.font = cell_font_white
                cell.border = cell_border
                worksheet.getColumn(number).width = 20;
            })

            let week_days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']
            function dayFormat(value: any) {
                // value = value.indexOf('-') !== -1 ? value.split('-') : value.split('-');
                // value = value[1] + '-' + value[0] + '-' + value[2]
                return week_days[new Date(value).getDay()]
            }

            data.forEach((due: any) => {
                var cell_number = 1
                const cellvalues = [
                    due.due_date ? due.due_date : '--',
                    dayFormat(due.due_date) ? dayFormat(due.due_date) : '--',
                    due.gst.gst_no ? due.gst.gst_no : '--',
                    due.gst.state ? due.gst.state : '--',
                    due.gst.trade_name ? due.gst.trade_name : '--',
                    due.tax_period ? due.tax_period : '--',
                    due.case_data.case_description[due.case_data.case_description.length - 1] ? due.case_data.case_description[due.case_data.case_description.length - 1] : '--'
                ]
                const row = worksheet.addRow(cellvalues);
                table_headers.forEach(() => {
                    var cellData = row.getCell(cell_number)
                    cellData.border = cell_border
                    cellData.font = cell_font_black
                    cell_number++
                })
            })

            const note = worksheet.addRow(['Note', 'Login to solutions.zentaxclinic.com and get solutions to Notices or Expert opinions within 48 hours'])
            note.eachCell((cell, number) => {
                if (number === 1) {
                    cell.fill = {
                        type: 'pattern',
                        pattern: 'solid',
                        fgColor: { argb: '6495ED' },
                        bgColor: { argb: '6495ED' }
                    };
                    cell.font = cell_font_white
                    cell.border = cell_border
                    worksheet.getColumn(number).width = 20;
                } else {
                    cell.border = cell_border
                    cell.font = cell_font_black
                }
            })

            workbook.xlsx.writeBuffer().then((data: any) => {
                const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
                fs.saveAs(blob, fileName + '.xlsx');
                setDownloading && setDownloading(false);

            });
        }, (err: any) => {
            console.log(err)
            setDownloading && setDownloading(false);

        })
    },
    // ....

    // userslist download
    users_list: function (page: any, rowsPerPage: any, filterQuery?: any, searchquery?: any, setDownloading?: (downloading: boolean) => void) {
        const table_headers = ['User Name', 'E-mail ID', 'Mob.Number', 'Registered Date', 'Status', 'GstIn Managed', 'Incorrect Password', 'Total Notice', 'No Action Required', 'Action to be taken', 'Action taken', 'Last sync', 'Sync count', 'Captcha used count'];
        const cell_border = Object({ top: { style: 'thin', color: { argb: '0000000' } }, left: { style: 'thin', color: { argb: '0000000' } }, bottom: { style: 'thin', color: { argb: '0000000' } }, right: { style: 'thin', color: { argb: '0000000' } } })
        const cell_font_white = Object({ name: 'Arial', size: 10, bold: true, color: { argb: 'FFFFFFF' }, })
        const cell_font_black = Object({ name: 'Arial', size: 10, color: { argb: '00000000' }, })

        let query: any = {

        }

        let cf_code: any = localStorage.getItem('cf_code')

        if (localStorage.getItem('is_cf') === 'true') {
            const parsed_cf_code: string = JSON.parse(cf_code);
            query['cf_code'] = parsed_cf_code
        }
        if (searchquery) {
            query['search'] = searchquery
        }

        serviceConfig.get("profile_user", true, query || null, null).then((data: any) => {
            console.log(data, 'Gst list')
            const workbook = new Workbook();
            const fileName = 'users_' + new Date().toLocaleDateString();
            const worksheet = workbook.addWorksheet('YUser Details')

            const userData = JSON.parse(localStorage.getItem('userData') || '{}')
            console.log(JSON.parse(localStorage.getItem('userData') || '{}'), 'userData from users module')
            const label_headers = worksheet.addRow(['User Name', 'Email', 'Contact Number', 'Download at', 'Report by'])
            label_headers.eachCell((cell, number) => {
                cell.fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: '6495ED' },
                    bgColor: { argb: '6495ED' }
                };
                cell.font = cell_font_white
                cell.border = cell_border
                worksheet.getColumn(number).width = 20;
            })
            const label_values = worksheet.addRow([userData.user_name, userData.email, userData.contact_number, new Date().toLocaleDateString() + ' ' + new Date().toLocaleTimeString(), 'Zentax Clinic'])
            label_values.eachCell((cell, number) => {
                cell.border = cell_border
                cell.font = cell_font_black
            })
            const empty_row = worksheet.addRow([])

            const header = worksheet.addRow(table_headers)
            header.eachCell((cell, number) => {
                cell.fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: '6495ED' },
                    bgColor: { argb: '6495ED' }
                };
                cell.font = cell_font_white
                cell.border = cell_border
                worksheet.getColumn(number).width = 20;
            })

            // let week_days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']
            // function dayFormat(value: any) {
            //     value = value.indexOf('/') !== -1 ? value.split('/') : value.split('-');
            //     value = value[1] + '/' + value[0] + '/' + value[2]
            //     return week_days[new Date(value).getDay()]
            // }

            data.forEach((user: any) => {
                const formattedCreatedAt = user.created_at ? new Date(user.created_at).toLocaleDateString() + ' ' + new Date(user.created_at).toLocaleTimeString() : '--';

                const rowValues = [
                    user.user_name,
                    user.email,
                    user.contact_number,
                    user.created_at,
                    user.user_status ? 'Active' : 'Deactive',
                    user.user_counts ? user.user_counts.total_gst : '--',
                    user.user_counts ? user.user_counts.wrong_gst : '--',
                    user.user_counts ? user.user_counts.total_notices : '--',
                    user.user_counts ? user.user_counts.no_action_required : '--',
                    user.user_counts ? user.user_counts.action_to_be_taken : '--',
                    user.user_counts ? user.user_counts.action_taken : '--',
                    user.last_sync ? user.last_sync : '--',
                    user.sync_count ? user.sync_count : '--',
                    user.captcha_count ? user.captcha_count : '--'];

                // Add row with cell values
                const row = worksheet.addRow(rowValues);
                // var cell_number = 1
                // table_headers.forEach(() => {
                //     var cellData = row.getCell(cell_number)
                //     cellData.border = cell_border
                //     cellData.font = cell_font_black
                //     cell_number++
                // })
                // Iterate over each cell to apply formatting
                row.eachCell((cell, cellNumber) => {
                    cell.border = cell_border;
                    cell.font = cell_font_black;
                });
            });

            // data.forEach((user: any) => {
            //     var cell_number = 1
            //     const row = worksheet.addRow([user.user_name, user.email, user.contact_number, user.user_status ? 'Active' : 'Deactive']);
            //     table_headers.forEach(() => {
            //         var cellData = row.getCell(cell_number)
            //         cellData.border = cell_border
            //         cellData.font = cell_font_black
            //         cell_number++
            //     })
            // })

            const note = worksheet.addRow(['Note', 'Login to solutions.zentaxclinic.com and get solutions to Notices or Expert opinions within 48 hours'])
            note.eachCell((cell, number) => {
                if (number === 1) {
                    cell.fill = {
                        type: 'pattern',
                        pattern: 'solid',
                        fgColor: { argb: '6495ED' },
                        bgColor: { argb: '6495ED' }
                    };
                    cell.font = cell_font_white
                    cell.border = cell_border
                    worksheet.getColumn(number).width = 20;
                } else {
                    cell.border = cell_border
                    cell.font = cell_font_black
                }
            })

            workbook.xlsx.writeBuffer().then((data: any) => {
                const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
                fs.saveAs(blob, fileName + '.xlsx');
                setDownloading && setDownloading(false);

            });
        }, (err: any) => {
            console.log(err)
            setDownloading && setDownloading(false);

        })
    },
    // ....

    gst_template: function () {
        var randomNumber = Math.floor(Math.random() * 1000)

        const workbook = new Workbook();
        const fileName = 'template_' + new Date().toLocaleDateString() + '_' + randomNumber;
        const worksheet = workbook.addWorksheet('template')

        const label_headers = worksheet.addRow(['User Name', 'Password'])
        const cell_border = Object({ top: { style: 'thin', color: { argb: '0000000' } }, left: { style: 'thin', color: { argb: '0000000' } }, bottom: { style: 'thin', color: { argb: '0000000' } }, right: { style: 'thin', color: { argb: '0000000' } } })
        const cell_font_white = Object({ name: 'Arial', size: 10, bold: true, color: { argb: 'FFFFFFF' }, })
        const cell_font_black = Object({ name: 'Arial', size: 10, color: { argb: '00000000' }, })

        label_headers.eachCell((cell, number) => {
            cell.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: { argb: '6495ED' },
                bgColor: { argb: '6495ED' }
            };
            cell.font = cell_font_white
            cell.border = cell_border
            worksheet.getColumn(number).width = 20;
        })

        workbook.xlsx.writeBuffer().then((data: any) => {
            const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
            fs.saveAs(blob, fileName + '.xlsx');
        })
    },
    // ....

    //sync monitor download
    sync_monitor: function (page: any, rowsPerPage: any, filterQuery?: any, searchquery?: any, setDownloading?: (downloading: boolean) => void) {

        const table_headers = ['Sync Start Time', 'Sync End Time', 'User Name', 'No. of GSTIN synced', 'No. of notices fetched', 'Total Time for sync (M:S)'];
        const cell_border = Object({ top: { style: 'thin', color: { argb: '0000000' } }, left: { style: 'thin', color: { argb: '0000000' } }, bottom: { style: 'thin', color: { argb: '0000000' } }, right: { style: 'thin', color: { argb: '0000000' } } })
        const cell_font_white = Object({ name: 'Arial', size: 10, bold: true, color: { argb: 'FFFFFFF' }, })
        const cell_font_black = Object({ name: 'Arial', size: 10, color: { argb: '00000000' }, })

        let query: any = {

        }

        if (filterQuery) {
            if (filterQuery.gst_no) {
                query['gst'] = filterQuery.gst_no.join(',')
            }
            if (filterQuery.state) {
                query['state'] = filterQuery.state.join(',')
            }
            if (filterQuery.trade_name) {
                query['trade'] = filterQuery.trade_name.join(',')
            }
            if (filterQuery.user_name) {
                query['user_name'] = filterQuery.user_name.join(',')
            }
        }

        if (searchquery) {
            query['search'] = searchquery
        }
        serviceConfig.get("gst_sync", true, query || null, null).then((data: any) => {
            console.log(data, 'Gst list')
            const workbook = new Workbook();
            const fileName = 'sync_monitor_' + new Date().toLocaleDateString()
            const worksheet = workbook.addWorksheet('Gst Details')

            const userData = JSON.parse(localStorage.getItem('userData') || '{}')

            const label_headers = worksheet.addRow(['User Name', 'Email', 'Contact Number', 'Download at', 'Report by'])
            label_headers.eachCell((cell, number) => {
                cell.fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: '6495ED' },
                    bgColor: { argb: '6495ED' }
                };
                cell.font = cell_font_white
                cell.border = cell_border
                worksheet.getColumn(number).width = 20;
            })
            const label_values = worksheet.addRow([userData.user_name, userData.email, userData.contact_number, new Date().toLocaleDateString() + ' ' + new Date().toLocaleTimeString(), 'Zentax Clinic'])
            label_values.eachCell((cell, number) => {
                cell.border = cell_border
                cell.font = cell_font_black
            })
            const header = worksheet.addRow(table_headers)
            header.eachCell((cell, number) => {
                cell.fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: '6495ED' },
                    bgColor: { argb: '6495ED' }
                };
                cell.font = cell_font_white
                cell.border = cell_border
                worksheet.getColumn(number).width = 20;
            })

            function dateFormat(value: any) {
                function join(date: any, options: any, separator: any) {
                    function format(option: any) {
                        let formatter = new Intl.DateTimeFormat('en', option);
                        return formatter.format(date);
                    }
                    let joined = options.map(format).join(separator);
                    return joined + ', ' + new Date(value).toLocaleTimeString();
                }

                let options = [{ day: 'numeric' }, { month: 'short' }, { year: 'numeric' }];
                return value ? join(new Date(value), options, ' ') : '--';
            }

            data.forEach((gst: any) => {
                var cell_number = 1
                function millisToMinutesAndSeconds(millis: any) {
                    var minutes = Math.floor(millis / 60000);
                    var seconds: any = ((millis % 60000) / 1000).toFixed(0);
                    return (minutes < 10 ? '0' : '') + minutes + ":" + (seconds < 10 ? '0' : '') + seconds;
                }

                let sync_end: any = new Date(gst.sync_end)
                let sync_start: any = new Date(gst.created_at)
                let diffMs = sync_end - sync_start

                const cellvalues = [
                    dateFormat(gst.sync_started) ? dateFormat(gst.sync_started) : '--',
                    dateFormat(gst.sync_end) ? dateFormat(gst.sync_end) : '--',
                    gst.user.user_name ? gst.user.user_name : '--',
                    gst.gst_list.length ? gst.gst_list.length : '--',
                    gst.gst_list ? gst.gst_list.map((obj: any) => obj.notice_count).reduce((accumulator: any, current: any) => accumulator + current, 0) : '--',
                    gst.sync_processed ? millisToMinutesAndSeconds(diffMs) : '--'
                ]
                const row = worksheet.addRow(cellvalues);
                table_headers.forEach(() => {
                    var cellData = row.getCell(cell_number)
                    cellData.border = cell_border
                    cellData.font = cell_font_black
                    cell_number++
                })
            })
            const note = worksheet.addRow(['Note', 'Login to solutions.zentaxclinic.com and get solutions to Notices or Expert opinions within 48 hours'])
            note.eachCell((cell, number) => {
                if (number === 1) {
                    cell.fill = {
                        type: 'pattern',
                        pattern: 'solid',
                        fgColor: { argb: '6495ED' },
                        bgColor: { argb: '6495ED' }
                    };
                    cell.font = cell_font_white
                    cell.border = cell_border
                    worksheet.getColumn(number).width = 20;
                } else {
                    cell.border = cell_border
                    cell.font = cell_font_black
                }
            })

            workbook.xlsx.writeBuffer().then((data: any) => {
                const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
                fs.saveAs(blob, fileName + '.xlsx');
                setDownloading && setDownloading(false);

            });
        }, (err: any) => {
            console.log(err)
            setDownloading && setDownloading(false);

        })
    },
    // ....

    // compliance dashboard download
    compliance_list: function (page: any, rowsPerPage: any, filterQuery?: any, searchquery?: any, setDownloading?: (downloading: boolean) => void) {

        const table_headers = ['Company Name', 'GSTIN', 'Type', 'Dec - 2023', 'Jan - 2024', 'Feb - 2024', 'Mar - 2024', 'Apr - 2024'];
        const cell_border = Object({ top: { style: 'thin', color: { argb: '0000000' } }, left: { style: 'thin', color: { argb: '0000000' } }, bottom: { style: 'thin', color: { argb: '0000000' } }, right: { style: 'thin', color: { argb: '0000000' } } })
        const cell_font_white = Object({ name: 'Arial', size: 10, bold: true, color: { argb: 'FFFFFFF' }, })
        const cell_font_black = Object({ name: 'Arial', size: 10, color: { argb: '00000000' }, })

        let query: any = { }

        if (filterQuery) {  
            if (filterQuery.gst_no) {
                query['gst'] = filterQuery.gst_no.join(',')
            }
            if (filterQuery.state) {
                query['state'] = filterQuery.state.join(',')
            }
            if (filterQuery.trade_name) {
                query['trade'] = filterQuery.trade_name.join(',')
            }
            if (filterQuery.user_name) {
                query['user_name'] = filterQuery.user_name.join(',')
            }
        }

        // if (searchquery) {
        //     query['search'] = searchquery
        // }
        serviceConfig.get("compliance_data", true, query || null, null).then((data: any) => {
            console.log(data, 'Gst list')
            const workbook = new Workbook();
            const fileName = 'compliance_data_' + new Date().toLocaleDateString()
            const worksheet = workbook.addWorksheet('Gst Details')

            const userData = JSON.parse(localStorage.getItem('userData') || '{}')

            const label_headers = worksheet.addRow(['User Name', 'Email', 'Contact Number', 'Download at', 'Report by'])
            label_headers.eachCell((cell, number) => {
                cell.fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: '6495ED' },
                    bgColor: { argb: '6495ED' }
                };
                cell.font = cell_font_white
                cell.border = cell_border
                worksheet.getColumn(number).width = 20;
            })
            const label_values = worksheet.addRow([userData.user_name, userData.email, userData.contact_number, new Date().toLocaleDateString() + ' ' + new Date().toLocaleTimeString(), 'Zentax Clinic'])
            label_values.eachCell((cell, number) => {
                cell.border = cell_border
                cell.font = cell_font_black
            })
            const header = worksheet.addRow(table_headers)
            header.eachCell((cell, number) => {
                cell.fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: '6495ED' },
                    bgColor: { argb: '6495ED' }
                };
                cell.font = cell_font_white
                cell.border = cell_border
                worksheet.getColumn(number).width = 20;
            })

            data.forEach((gst: any) => {
                if (gst.gst.gst_no && gst.form_names) {
                    // Adding the first row with company details and the first form details
                    const firstForm = gst.form_names[0];
                    const firstFormName = firstForm.formName;
                    const firstFilingStatuses = firstForm.retPrds.map((period: any) => period.filingStatus);

                    const cellvalues = [
                        gst.gst.trade_name ? gst.gst.trade_name : '--',
                        gst.gst.gst_no ? gst.gst.gst_no : '--',
                        firstFormName ? firstFormName : '--',
                        ...firstFilingStatuses]
                    const row = worksheet.addRow(cellvalues);

                    let cell_number = 1;
                    table_headers.forEach(() => {
                        const cellData = row.getCell(cell_number);
                        cellData.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
                        cellData.font = { name: 'Arial', color: { argb: 'FF000000' } };
                        cell_number++;

                        const startingCellIndex = 4; // Assuming the index of the fourth cell is 4

                        for (let cellIndex = startingCellIndex; cellIndex < startingCellIndex + firstFilingStatuses.length; cellIndex++) {
                            const cellData = row.getCell(cellIndex);
                            cellData.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
                            cellData.font = { name: 'Arial', color: { argb: 'FF000000' } };

                            // Check if the filing status is not equal to 'Filed' and apply background color
                            if (firstFilingStatuses[cellIndex - startingCellIndex] !== 'Filed') {
                                cellData.fill = {
                                    type: 'pattern',
                                    pattern: 'solid',
                                    fgColor: { argb: 'E96919' } // Red background color
                                };
                            }
                        }
                    });

                    // Adding subsequent forms in new rows with empty first two cells
                    gst.form_names.slice(1).forEach((form: any) => {
                        const formName = form.formName;
                        const filingStatuses = form.retPrds.map((period: any) => period.filingStatus);

                        const nextRow = worksheet.addRow(['', '', formName, ...filingStatuses]);

                        let cell_number = 4; // Starting from the fourth cell
                        table_headers.slice(3).forEach((header: any, index) => {
                            const cellData = nextRow.getCell(cell_number);
                            cellData.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
                            cellData.font = { name: 'Arial', color: { argb: 'FF000000' } };

                            // Check if the filing status is not equal to 'Filed' and apply background color
                            if (filingStatuses[index] !== 'Filed') {
                                cellData.fill = {
                                    type: 'pattern',
                                    pattern: 'solid',
                                    fgColor: { argb: 'E96919' } // Red background color
                                };
                            }

                            cell_number++;
                        });
                    });

                    // Adding an empty row after each set of data
                    worksheet.addRow([]);
                }
            });

            const note = worksheet.addRow(['Note', 'Login to solutions.zentaxclinic.com and get solutions to Notices or Expert opinions within 48 hours'])
            note.eachCell((cell, number) => {
                if (number === 1) {
                    cell.fill = {
                        type: 'pattern',
                        pattern: 'solid',
                        fgColor: { argb: '6495ED' },
                        bgColor: { argb: '6495ED' }
                    };
                    cell.font = cell_font_white
                    cell.border = cell_border
                    worksheet.getColumn(number).width = 20;
                } else {
                    cell.border = cell_border
                    cell.font = cell_font_black
                }
            })

            workbook.xlsx.writeBuffer().then((data: any) => {
                const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
                fs.saveAs(blob, fileName + '.xlsx');
                setDownloading && setDownloading(false);

            });
        }, (err: any) => {
            console.log(err)
            setDownloading && setDownloading(false);

        })
    },
    // ....

    // manage gst menu download
    manage_gst: function (page: any, rowsPerPage: any, filterQuery?: any, sorterQuery?: any, searchquery?: any, setDownloading?: (downloading: boolean) => void) {

        const table_headers = ['GSTIN', 'State', 'Trade Name', 'User ID', 'Password', 'Last Sync', 'Sync Status'];
        const cell_border = Object({ top: { style: 'thin', color: { argb: '0000000' } }, left: { style: 'thin', color: { argb: '0000000' } }, bottom: { style: 'thin', color: { argb: '0000000' } }, right: { style: 'thin', color: { argb: '0000000' } } })
        const cell_font_white = Object({ name: 'Arial', size: 10, bold: true, color: { argb: 'FFFFFFF' }, })
        const cell_font_black = Object({ name: 'Arial', size: 10, color: { argb: '00000000' }, })

        let query: any = {

        }

        if (filterQuery) {
            if (filterQuery.gst_no) {
                query['gst_no'] = filterQuery.gst_no.join(',')
            }
            if (filterQuery.state) {
                query['state'] = filterQuery.state.join(',')
            }
            if (filterQuery.trade_name) {
                query['trade'] = filterQuery.trade_name.join(',')
            }
            if (filterQuery.tradename) {
                query['trade_name'] = filterQuery.tradename.join(',')
            }
            if (filterQuery.user_name) {
                query['user_name'] = filterQuery.user_name.join(',')
            }
        }

        if (sorterQuery && sorterQuery.field) {
            query['sort'] = `${sorterQuery.field},${sorterQuery.order === "ascend" ? "asc" : "desc"}`
        }

        if (searchquery) {
            query['search'] = searchquery
        }

        serviceConfig.get("manageGst", true, query || null, null).then((data: any) => {
            console.log(data, 'Gst list')
            const workbook = new Workbook();
            const fileName = 'manage_gst_' + new Date().toLocaleDateString()
            const worksheet = workbook.addWorksheet('Gst Details')

            const userData = JSON.parse(localStorage.getItem('userData') || '{}')

            const label_headers = worksheet.addRow(['User Name', 'Email', 'Contact Number', 'Download at', 'Report by'])
            label_headers.eachCell((cell, number) => {
                cell.fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: '6495ED' },
                    bgColor: { argb: '6495ED' }
                };
                cell.font = cell_font_white
                cell.border = cell_border
                worksheet.getColumn(number).width = 20;
            })
            const label_values = worksheet.addRow([userData.user_name, userData.email, userData.contact_number, new Date().toLocaleDateString() + ' ' + new Date().toLocaleTimeString(), 'Zentax Clinic'])
            label_values.eachCell((cell, number) => {
                cell.border = cell_border
                cell.font = cell_font_black
            })
            const header = worksheet.addRow(table_headers)
            header.eachCell((cell, number) => {
                cell.fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: '6495ED' },
                    bgColor: { argb: '6495ED' }
                };
                cell.font = cell_font_white
                cell.border = cell_border
                worksheet.getColumn(number).width = 20;
            })

            function dateFormat(value: any) {
                function join(date: any, options: any, separator: any) {
                    function format(option: any) {
                        let formatter = new Intl.DateTimeFormat('en', option);
                        return formatter.format(date);
                    }
                    let joined = options.map(format).join(separator);
                    return joined + ', ' + new Date(value).toLocaleTimeString();
                }

                let options = [{ day: 'numeric' }, { month: 'short' }, { year: 'numeric' }];
                return value ? join(new Date(value), options, ' ') : '--';
            }

            data.forEach((gst: any) => {
                var cell_number = 1
                const cellvalues = [
                    gst.gst_no ? gst.gst_no : '--',
                    gst.state ? gst.state : '--',
                    gst.trade_name ? gst.trade_name : '--',
                    gst.user_name ? gst.user_name : '--',
                    gst.password ? gst.password : '--',
                    dateFormat(gst.last_sync) ? dateFormat(gst.last_sync) : '--',
                    gst.sync_status ? gst.sync_status : '--'
                ]
                const row = worksheet.addRow(cellvalues);
                table_headers.forEach(() => {
                    var cellData = row.getCell(cell_number)
                    cellData.border = cell_border
                    cellData.font = cell_font_black
                    cell_number++
                })
            })
            const note = worksheet.addRow(['Note', 'Login to solutions.zentaxclinic.com and get solutions to Notices or Expert opinions within 48 hours'])
            note.eachCell((cell, number) => {
                if (number === 1) {
                    cell.fill = {
                        type: 'pattern',
                        pattern: 'solid',
                        fgColor: { argb: '6495ED' },
                        bgColor: { argb: '6495ED' }
                    };
                    cell.font = cell_font_white
                    cell.border = cell_border
                    worksheet.getColumn(number).width = 20;
                } else {
                    cell.border = cell_border
                    cell.font = cell_font_black
                }
            })

            workbook.xlsx.writeBuffer().then((data: any) => {
                const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
                fs.saveAs(blob, fileName + '.xlsx');
                setDownloading && setDownloading(false);

            });
        }, (err: any) => {
            console.log(err)
            setDownloading && setDownloading(false);

        })
    },
    // ....

    download_notices: function (filterQuery?: any, searchquery?: any, setDownloading?: (downloading: boolean) => void) {
        const table_headers = ['Trade name', 'GSTIN', 'Tax Period', 'Notice date', 'Due date', 'Description', 'State', 'ARN', 'Reason', 'GST Portal Status', 'Case ID', 'Section', 'Status', 'Demand Amount'];
        const cell_border = Object({ top: { style: 'thin', color: { argb: '0000000' } }, left: { style: 'thin', color: { argb: '0000000' } }, bottom: { style: 'thin', color: { argb: '0000000' } }, right: { style: 'thin', color: { argb: '0000000' } } })
        const cell_font_white = Object({ name: 'Arial', size: 10, bold: true, color: { argb: 'FFFFFFF' }, })
        const cell_font_black = Object({ name: 'Arial', size: 10, color: { argb: '00000000' }, })

        serviceConfig.get("get_notices", true, filterQuery || null, null).then((data: any) => {
            console.log(data, 'Gst list')
            const workbook = new Workbook();
            const fileName = 'gst_notice_details_' + new Date().toLocaleDateString();
            const worksheet = workbook.addWorksheet('Notice Details')

            const userData = JSON.parse(localStorage.getItem('userData') || '{}')

            const label_headers = worksheet.addRow(['User Name', 'Email', 'Contact Number', 'Download at', 'Report by'])
            label_headers.eachCell((cell, number) => {
                cell.fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: '6495ED' },
                    bgColor: { argb: '6495ED' }
                };
                cell.font = cell_font_white
                cell.border = cell_border
                worksheet.getColumn(number).width = 20;
            })
            const label_values = worksheet.addRow([userData.user_name, userData.email, userData.contact_number, new Date().toLocaleDateString() + ' ' + new Date().toLocaleTimeString(), 'Zentax Clinic'])
            label_values.eachCell((cell, number) => {
                cell.border = cell_border
                cell.font = cell_font_black
            })

            const header = worksheet.addRow(table_headers)
            header.eachCell((cell, number) => {
                cell.fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: '6495ED' },
                    bgColor: { argb: '6495ED' }
                };
                cell.font = cell_font_white
                cell.border = cell_border
                worksheet.getColumn(number).width = 20;
            })

            data.forEach((notice: any) => {
                var cell_number = 1
                const row = worksheet.addRow([notice.gst.trade_name, notice.gst.gst_no, notice.tax_period, notice.issue_date, notice.due_date, notice.reason, notice.gst.state, notice.case_data.case_arn_no, notice.case_data.case_description[notice.case_data.case_description.length - 1], notice.case_data.portal_status, notice.case_data.case_id, notice.section, notice.notice_status, notice.tax_amount]);
                table_headers.forEach(() => {
                    var cellData = row.getCell(cell_number)
                    cellData.border = cell_border
                    cellData.font = cell_font_black
                    cell_number++
                })
            })

            const note = worksheet.addRow(['Note', 'Login to solutions.zentaxclinic.com and get solutions to Notices or Expert opinions within 48 hours'])
            note.eachCell((cell, number) => {
                if (number === 1) {
                    cell.fill = {
                        type: 'pattern',
                        pattern: 'solid',
                        fgColor: { argb: '6495ED' },
                        bgColor: { argb: '6495ED' }
                    };
                    cell.font = cell_font_white
                    cell.border = cell_border
                    worksheet.getColumn(number).width = 20;
                } else {
                    cell.border = cell_border
                    cell.font = cell_font_black
                }
            })


            workbook.xlsx.writeBuffer().then((data: any) => {
                const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
                fs.saveAs(blob, fileName + '.xlsx');
                setDownloading && setDownloading(false);

            });
        }, (err: any) => {
            console.log(err)
            setDownloading && setDownloading(false);

        })
    },
}