// react 
import { useCallback, useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom';
// ..........

// scss file
import './dueDashboard.scss'
// ..........

// package components
import { Button, Checkbox, DatePicker, Modal } from 'antd'
import Table from 'antd/es/table'
import { parseISO, format } from 'date-fns';
import { enUS } from 'date-fns/locale';
// ..........

// child components
import { useGlobalState } from '../../Statecontext'
import { NotifyAlert } from '../../components/notify_alert'
import { ZTable2 } from '../../components/table2'
import { serviceConfig } from '../../core/services'
// ..........

// icons
import filterIcon from '../../assets/images/filter.svg';
import active_filtericon from '../../assets/images/filter_active_icon.svg';
import { Utils } from '../../core/utils/utils'
// ..........

export const DueDashboard = () => {

	// form values states
	const [sourceduedate, setSourceduedate] = useState<any>([])
	// ..........

	// alerts and modal states
	const [notifyOpen, setNotifyOpen] = useState(false)
	const [notifyType, setNotifyType] = useState<any>()
	const [notifyMessage, setNotifyMessage] = useState('')

	// limit & filter states
	const [loading, setLoading] = useState(false)
	const [filter, setFilter] = useState(false)
	const [fromDate, setFromDate] = useState<any>('')
	const [toDate, setToDate] = useState<any>('')
	const { masterData, overviewDetails, filterQuery, setFilterQuery, sorterQuery, searchquery, totalpages, setTotalpages, page, tabactive, rowsPerPage, userdata } = useGlobalState();

	//  datas states
	const [noticeData, setNoticeData] = useState<any>([])
	const [userTradeMaster, setUserTradeMaster] = useState<any>([])
	const [userStateMaster, setUserStateMaster] = useState<any>([])
	const [userGSTMaster, setUserGSTMaster] = useState<any>([])

	// reference states
	const dueDashboardTable = useRef<any>()
	const { ticket } = Utils();
	const navigate = useNavigate()
	// date filter


	const handleDateFilter = () => {
		function dateFormat(date: any) {
			let dates = date.split('T')
			return dates[0]
		}
		let query: any = { due_from_date: dateFormat(fromDate.$d.toISOString()), due_to_date: dateFormat(toDate.$d.toISOString()) }
		dueDashboardTable.current.setTableQuery(query)
	}

	const clearDateFilter = () => {
		setFromDate('')
		setToDate('')
		dueDashboardTable.current.setTableQuery({ due_from_date: '', due_to_date: '' })
	}
	// .............

	// data get function
	const loadGNotices = useCallback((search_data?: any) => {
		setLoading(true)
		let query: any = {
			by_page: true,
			page: page,
			page_size: rowsPerPage
		}
		if (searchquery) {
			query['search'] = searchquery;
		}

		if (filterQuery.due_from_date && filterQuery.due_to_date) {
			query['notice_start_due_date'] = filterQuery.due_from_date
			query['notice_end_due_date'] = filterQuery.due_to_date
		}

		if (filterQuery.gst_no) {
			query['gst_no'] = filterQuery.gst_no.join(',')
		}

		if (overviewDetails.gst_no) {
			query['gst_no'] = overviewDetails.gst_no
		}

		if (filterQuery.state) {
			query['state'] = filterQuery.state.join(',')
		}
		if (filterQuery.due_day) {
			query['due_day'] = filterQuery.due_day.join(',')
		}
		if (filterQuery.trade_name) {
			query['trade_name'] = filterQuery.trade_name.join(',')
		}
		if (filterQuery.tradename) {
			query['trade_name'] = filterQuery.tradename.join(',')
		}
		if (filterQuery.tax_period && filterQuery.tax_period.length > 0) {
			query['tax_period'] = filterQuery.tax_period.join(',')
		}
		if (filterQuery.seven_days_due) {
			query['seven_days_due'] = true
		}
		if (filterQuery.over_due) {
			query['over_due'] = true
		}
		if (sorterQuery && sorterQuery.columnKey) {
			query['sort'] = `${sorterQuery.columnKey},${sorterQuery.order === 'ascend' ? 'asc' : 'desc'}`
		}

		query['is_due'] = true

		serviceConfig.get("get_notices", true, query, null).then((data: any) => {

			setNoticeData(data.results || data.data)
			setTotalpages(data.count)
			setLoading(false)
			let week_days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']
			data.results.forEach((res: any) => {
				setSourceduedate((duedate: any) => [...duedate, res.due_date])
				res['due_day'] = res.due_date ? week_days[new Date(res.due_date).getDay()] : '--';
			});
		}, (err: any) => {
			console.log(err)
			setLoading(false)
			if (err.message === "Request failed with status code 401") {
				navigate('/login')
				localStorage.clear()
				window.location.reload();
			}
		})
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [page, rowsPerPage, filterQuery, sorterQuery, searchquery])



	useEffect(() => {
		loadGNotices()
		const storedUserRole = localStorage.getItem('userRole');

	}, [loadGNotices, filterQuery])
	// ...........

	// getsolution
	const createTickets = (selectedNotice?: any) => {
		if (selectedNotice.length > 0) {
			ticket(selectedNotice).then((data: any) => {
				setNotifyType("success")
				setNotifyMessage("Our experts team contact you shortly.")
				setNotifyOpen(true)
				loadGNotices()
			}).catch((err: any) => {
				setNotifyType("error")
				setNotifyMessage(err.response.message)
				setNotifyOpen(true)
			})
		} else {
			setNotifyType("warning")
			setNotifyMessage("Please select atleast one notice to get soultion!")
			setNotifyOpen(true)
		}
	}
	// ...........

	// button details props
	const buttondetails = [
		{
			type: 'download',
			name: 'Download',
			theme: 'theme4',
			downloadquery: 'due_notices',
			conditions: ['if_list'],
		}
	]
	// ...........

	// table columns and filter requirement props
	const columns1 = [
		{
            align: 'center',
			title: 'Due date', dataIndex: 'due_date', key: 'due_date',
			// filters: [
			// 	{
			// 		text: 'Male',
			// 		value: 'male',

			// 	}
			// ],
			filterIcon: () => filterQuery && filterQuery.due_from_date && filterQuery.due_to_date ? < img src={active_filtericon} alt='filter_active_icon' /> : <img src={filterIcon} alt='filter icon' />,
			filterDropdown: () => (
				<div style={{ display: 'flex', flexDirection: "column", padding: 10, gap: "10%" }}>
					<DatePicker value={fromDate} onChange={(date: any) => { setFromDate(date); console.log(date) }} style={{ marginBottom: 10 }} placeholder='From Date' />
					<DatePicker value={toDate} onChange={(date: any) => setToDate(date)} placeholder="To Date" />
					<div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", marginTop: 10 }}>
						<Button
							className='filter-secondary-btn'
							style={{ backgroundColor: "none", border: "none" }}
							onClick={clearDateFilter}>Reset</Button>
						<Button
							className='filter-primary-btn'
							disabled={!fromDate || !toDate}
							onClick={handleDateFilter}>Ok</Button>
					</div>
				</div>
			),
			render(value: any, record: any, index: any) {
				return value && value.indexOf('/') !== -1 ? value.split('/').join('-') : value ? value : '--'
			},
			sorter: true,
			sortDirection: sorterQuery.columnKey === 'due_date' ? sorterQuery.order : 'ascend'
		},
		{
			title: 'Day',
			dataIndex: 'due_day',
			key: 'due_day',
            align: 'center',
			// filters: userdata && userdata.role.role_name === 'Super Admin' ? null :
			// 	[
			// 		{
			// 			text: 'Sunday',
			// 			value: '6',
			// 		},
			// 		{
			// 			text: 'Monday',
			// 			value: '0',
			// 		},
			// 		{
			// 			text: 'Tuesday',
			// 			value: '1',
			// 		},
			// 		{
			// 			text: 'Wednesday',
			// 			value: '2',
			// 		},
			// 		{
			// 			text: 'Thursday',
			// 			value: '3',
			// 		},
			// 		{
			// 			text: 'Friday',
			// 			value: '4',
			// 		},
			// 		{
			// 			text: 'Saturday',
			// 			value: '5',
			// 		},
			// 	],
			// filterIcon: userdata && userdata.role.role_name === 'Super Admin' ? undefined : () => <img src={filterIcon} alt='filter icon' />,
			render(value: any, record: any, index: any) {
				return value ? value : '--';
			},
			filterSearch: userdata && userdata.role.role_name === 'Super Admin' ? false : false,
			sorter: false,
			sortDirection: sorterQuery.columnKey === 'due_day' ? sorterQuery.order : 'ascend',
		},
		// {
		// 	title: 'GSTIN', dataIndex: 'gst', key: 'gst_no',
		// 	filters: masterData.gst
		// 		.filter((item: any) => item !== null && item.trim() !== "")
		// 		.map((item: any) => {
		// 			return {
		// 				text: item,
		// 				value: item,
		// 			};
		// 		}),
		// 	filterIcon: () => filterQuery && filterQuery.gst_no ? < img src={active_filtericon} alt='filter_active_icon' /> : <img src={filterIcon} alt='filter icon' />,
		// 	render(value: any, record: any, index: any) {
		// 		return (<span style={{ whiteSpace: 'pre-wrap' }}>{value.gst_no} </span>)
		// 	},
		// 	filterSearch: true,
		// 	sorter: true,
		// 	sortDirection: sorterQuery.columnKey === 'gst_no' ? sorterQuery.order : 'ascend'

		// },
		{
			title: 'State',
			dataIndex: 'gst',
			key: 'state',
			align: 'center',
			filters: masterData.states
				.filter((item: any) => item !== null && item.trim() !== "")
				.map((item: any) => {
					return {
						text: item,
						value: item,
					};
				}),
			render(value: any, record: any, index: any) {
				return value.state
			},

			filterIcon: () => filterQuery && filterQuery.state ? <img src={active_filtericon} alt='filter_active_icon' /> : <img src={filterIcon} alt='filter icon' />,
			filterSearch: true,
			sorter: true,
			sortDirection: sorterQuery.columnKey === 'state' ? sorterQuery.order : 'ascend'

		},
		// {
		// 	title: 'Trade Name', dataIndex: 'gst', key: 'trade_name',

		// 	filters: masterData.trades
		// 		.filter((item: any) => item !== null && item.trim() !== "")
		// 		.map((item: any) => {
		// 			return {
		// 				text: item,
		// 				value: item,
		// 			};
		// 		}),
		// 	filterIcon: () => filterQuery && filterQuery.trade_name ? < img src={active_filtericon} alt='filter_active_icon' /> : <img src={filterIcon} alt='filter icon' />,
		// 	render(value: any, record: any, index: any) {
		// 		return (<span style={{ whiteSpace: 'pre-wrap' }}>{value.trade_name} </span>)
		// 	},
		// 	filterSearch: true,
		// 	sorter: true,
		// 	sortDirection: sorterQuery.columnKey === 'trade_name' ? sorterQuery.order : 'ascend'

		// },
		{
			align: 'center',
			title: 'Tax Period', dataIndex: 'tax_period', key: 'tax_period', filters: [
				{
					text: 'Jul 2017 - Mar 2018',
					value: 'Jul 2017 - Mar 2018',

				},
				{
					text: 'Apr 2018 - Mar 2019',
					value: 'Apr 2018 - Mar 2019',

				},
				{
					text: 'Apr 2019 - Mar 2020',
					value: 'Apr 2019 - Mar 2020',

				},
				{
					text: 'Apr 2020 - Mar 2021',
					value: 'Apr 2020 - Mar 2021',

				},
				{
					text: 'Apr 2021 - Mar 2022',
					value: 'Apr 2021 - Mar 2022',

				},
				{
					text: 'Apr 2022 - Mar 2023',
					value: 'Apr 2022 - Mar 2023',

				}
			],
			filterIcon: () => filterQuery && filterQuery.tax_period ? < img src={active_filtericon} alt='filter_active_icon' /> : <img src={filterIcon} alt='filter icon' />,
			filterSearch: true,
			sorter: true,
			sortDirection: sorterQuery.columnKey === 'tax_period' ? sorterQuery.order : 'ascend'

		},
		{
			align: 'center',
			title: 'Description', dataIndex: 'case_data', key: 'reason',
			// filters: [
			// 	{
			// 		text: 'Male',
			// 		value: 'male',

			// 	}
			// ],
			// filterIcon: () => <img src={filterIcon} alt='filter icon' />,
			render(value: any, record: any, index: any) {
				// function formatDesc(descs: any) {
				// 	let desc_data: any = []
				// 	descs.forEach((des: any) => {
				// 		desc_data.push(des.split(' ').pop())
				// 	});
				// 	return descs.pop()
				// }
				return (<span style={{ whiteSpace: 'break-spaces' }}>{value.case_description.length > 0 ? value.case_description[value.case_description.length - 1] : '--'}</span>)
			},

		},
		Table.SELECTION_COLUMN,
	];
	// ...........

	return (
		<div id='due-date-container' className="gst-container">
			<ZTable2 label={'Missed & Upcoming Due Dates'} button={buttondetails} columns={columns1} expand={false} ref={dueDashboardTable} tabledata={noticeData} loading={loading} duedate={sourceduedate} totalpages={totalpages} />
			<Modal open={filter} onCancel={() => setFilter(false)} style={{ top: 0, left: 0, height: 220, width: 'max-content', position: "absolute" }} width={'20%'} >
				<Checkbox value={"checkl"} /><span style={{ marginLeft: 10 }}>Sample filer</span><br />
				<Checkbox value={"checkl"} /><span style={{ marginLeft: 10 }}>Sample filer</span><br />
			</Modal>
			<NotifyAlert notifyOpen={notifyOpen} changeStatus={setNotifyOpen} notifyMessage={notifyMessage} notifyType={notifyType}></NotifyAlert>
		</div>
	)
}