// react
// .........

// scss file
import './latest_search.scss';
// .........

// package components
import { useCallback, useEffect, useRef, useState } from 'react';
// .........

// child components 
import { useGlobalState } from '../../Statecontext';
import { Button, Modal, Spin } from 'antd';
import { serviceConfig } from '../../core/services';
import { NotifyAlert } from '../../components/notify_alert';
// .......

// icons 
import AutorenewIcon from '@mui/icons-material/Autorenew';
import { Avatar } from '@mui/material';
import ArticleIcon from '@mui/icons-material/Article';
// .....

export const Searchdata = ({ anchorEl, setAnchorEl, searchdetails, searchloading, Selatricle }: any) => {

    // alert and modal
    const [notifyOpen, setNotifyOpen] = useState(false)
    const [notifyType, setNotifyType] = useState<any>()
    const [notifyMessage, setNotifyMessage] = useState('')
    // .....

    // reference states
    const searchRef = useRef<any>(null);
    const { searchquery, setIsManagegst, setOverviewDetails, setProgres, progres, masterData, setMasterData, setFilterQuery, setSorterQuery, setSearchquery, syncedCount, setPage, setTotalpages, setTabactive, setManageactive, tabview, setTabview, setSumpopup, setSumdrawer, setRowsPerPage, setreportModal, reportmodal, syncDetails, setChangeview, setSyncDetails, setChartquery } = useGlobalState();
    // .........

    // loading state
    // .......

    // data states
    const [articles, setArticles] = useState<any>([])
    // ........

    // form value state
    let [syncId, setSyncId] = useState<any>(null)
    // ......

    // modal  function
    const popupFunc = (label: any) => {
        if (label === 'Sync') {
            setreportModal(true)
        }
    }
    // .........

    const capitalizeSubstring = (str: string) => {
        if (!str) return '';
        const capitalized = str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
        return capitalized.length > 54 ? capitalized.substring(0, 54) + '...' : capitalized;
    };

    useEffect(() => {
        function handleClickOutside(event: any) {
            if (searchRef.current && !searchRef.current?.contains(event.target)) {
                setTimeout(() => {
                }, 500);
                setAnchorEl(false);
            }
        }
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchRef]);

    return (
        <>
            {anchorEl && <div className='latest_search_cover fade-out' ref={searchRef}>
                <div className='head_box'>
                    <span className='head_line'>Search Suggestions</span>
                </div>
                <div className='notify_content_cover'>
                    {searchloading &&
                        <Spin spinning={true} size='default' style={{ maxWidth: '100%', position: 'absolute', display: 'flex', marginLeft: '14vw', marginTop: '20vh' }}>
                        </Spin>
                    }
                    {searchdetails?.length !== 0 ? searchdetails?.map((item: any, index: any) => (
                        <div className='content_row' key={item.subject} onClick={() => { Selatricle(item) }}>
                            <span className='content_subject'>
                                <ArticleIcon className='icon' />
                            </span>
                            <span className='content_desc'>{capitalizeSubstring(item.title)}</span>
                        </div>)) :
                        <span style={{ color: 'rgba(0, 0, 0, 0.45)', margin: 'auto' }}>No results found!</span>
                    }
                </div>
            </div >}
            <NotifyAlert notifyOpen={notifyOpen} changeStatus={setNotifyOpen} notifyMessage={notifyMessage} notifyType={notifyType}></NotifyAlert>
        </>
    )
}