// react
// .........

// scss file
import './index.scss';
// .........

// package components
import { useEffect, useRef, useState } from 'react';
// .........

// child components 
import { useGlobalState } from '../../Statecontext';
import { Button, Modal, Spin, Table, Tag } from 'antd';
import { serviceConfig } from '../../core/services';
import { NotifyAlert } from '../../components/notify_alert';
// .......

// icons 
import AutorenewIcon from '@mui/icons-material/Autorenew';
import { Avatar } from '@mui/material';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
// .....

export const Notification = ({ anchorEl, setAnchorEl }: any) => {

    // alert and modal
    const [notifyOpen, setNotifyOpen] = useState(false)
    const [detailopen, setDetailopen] = useState(false);
    const [notifyType, setNotifyType] = useState<any>()
    const [notifyMessage, setNotifyMessage] = useState('')
    // .....

    // reference states
    const optionsRef = useRef<any>(null);
    const { setIsManagegst, setOverviewDetails, setProgres, progres, masterData, setMasterData, setFilterQuery, setSorterQuery, setSearchquery, syncedCount, setPage, setTotalpages, setTabactive, setManageactive, tabview, setTabview, setSumpopup, setSumdrawer, setRowsPerPage, setreportModal, reportmodal, syncDetails, setChangeview, setSyncDetails, setChartquery } = useGlobalState();
    // .........

    // loading state
    const [detailloading, setDetailloading] = useState(false);
    const [syncloading, setSyncloading] = useState(false)
    // .......

    // form value state
    let [syncId, setSyncId] = useState<any>(null)
    // ......

    // data states
    const [detailSource, setDetailsource] = useState<any>([]);
    // ......

    // modal  function
    const popupFunc = (label: any) => {
        if (label === 'Sync') {
            setreportModal(true)
        }
    }
    // .........

    // logout function

    // .........


    // sync last status function
    const getLastSyncStatus = () => {
        setSyncloading(true)
        serviceConfig.get("gst_sync", true, { 'last': true }, null).then((data: any) => {
            setSyncloading(false)
            if (data.length > 0) {
                setSyncDetails(data[0])
                setDetailsource(data[0].gst_list)
                // if (!data[0].sync_processed && data[0].is_sync_all) {
                if (!data[0].sync_processed) {
                    syncId = data[0].id
                    setSyncId(data[0].id)
                    getStatusofSyncAllProgress()
                }
            }
        }, (err: any) => {
            setSyncloading(false)
            setreportModal(false)
            setNotifyType("error")
            setNotifyMessage('Something Went Wrong!')
            setNotifyOpen(true)
        })
    }
    const getStatusofSyncAllProgress = () => {
        setSyncloading(true)
        setProgres({ data: { loading: true, label: 'Sync' } })

        serviceConfig.get("gst_sync_details", true, null, { id: syncId }).then((data: any) => {
            setSyncDetails(data)
            setSyncloading(false)
            syncId = data.id
            setSyncId(data.id)
            let timerInt = !data.sync_processed ? setTimeout(() => {
                getStatusofSyncAllProgress();
            }, (10 * 1000)) : null;
            if (data.sync_processed) {
                timerInt = null;
                setreportModal(true)
                setProgres({ data: { loading: false, label: '' } })
            }
        }, (err: any) => {
            setNotifyType("error")
            setNotifyMessage('Something Went Wrong!')
            setNotifyOpen(true)
            setSyncloading(false)
            setreportModal(false)
            setProgres({ data: { loading: false, label: '' } })
        })
    }
    // ........

    const [isFadingOut, setIsFadingOut] = useState(false);


    useEffect(() => {
        function handleClickOutside(event: any) {
            if (optionsRef.current && !optionsRef.current?.contains(event.target)) {
                setIsFadingOut(true);
                setTimeout(() => {
                }, 500);
                setAnchorEl(false);
            }
        }
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [optionsRef]);

    useEffect(() => {
        getLastSyncStatus()
    }, [])

    const detailcolumns = [
        {
            title: 'S.No',
            dataIndex: 'serial',
            key: 'serial',
            render: (text: any, record: any, index: number) => index + 1,
        },
        {
            title: 'User Name',
            dataIndex: 'gst',
            render(value: any, record: any, index: any) {
                return value.user_name
            }
        },
        {
            title: 'Start Time',
            dataIndex: 'sync_started',
            key: 'sync_started',
            render(value: any, record: any, index: any) {
                function join(date: any, options: any, separator: any) {
                    function format(option: any) {
                        let formatter = new Intl.DateTimeFormat('en', option);
                        return formatter.format(date);
                    }
                    let joined = options.map(format).join(separator);
                    return joined + ', ' + new Date(value).toLocaleTimeString();
                }

                let options = [{ day: 'numeric' }, { month: 'short' }, { year: 'numeric' }];
                return value ? join(new Date(value), options, ' ') : '--';
            },
        },
        {
            title: 'End Time',
            dataIndex: 'sync_end',
            key: 'sync_end',
            render(value: any, record: any, index: any) {
                function join(date: any, options: any, separator: any) {
                    function format(option: any) {
                        let formatter = new Intl.DateTimeFormat('en', option);
                        return formatter.format(date);
                    }
                    let joined = options.map(format).join(separator);
                    return joined + ', ' + new Date(value).toLocaleTimeString();
                }

                let options = [{ day: 'numeric' }, { month: 'short' }, { year: 'numeric' }];
                return value ? join(new Date(value), options, ' ') : '--';
            },
        },
        {
            title: 'Status', dataIndex: 'is_sync_failed', key: 'is_sync_failed',
            render(value: any, record: any, index: any) {
                if (value === true) {
                    return <Tag color='error' style={{ borderRadius: "25px", padding: '3px 10px', cursor: 'pointer' }} >Fail</Tag>
                } else {
                    return <Tag color='success' style={{ borderRadius: "25px", padding: '3px 10px', cursor: 'pointer' }} >Success</Tag>
                }
            },
            width: 100,
        },
        {
            title: 'Total Time for sync (M:S)',
            dataIndex: 'email',
            render(value: any, record: any, index: any) {
                function millisToMinutesAndSeconds(millis: any) {
                    var minutes = Math.floor(millis / 60000);
                    var seconds: any = ((millis % 60000) / 1000).toFixed(0);
                    return (minutes < 10 ? '0' : '') + minutes + ":" + (seconds < 10 ? '0' : '') + seconds;
                }

                let sync_end: any = new Date(record.sync_end)
                let sync_start: any = new Date(record.sync_started)
                let diffMs = sync_end - sync_start
                return <span style={{ fontWeight: 'bold', color: '#E96919' }}>{record.sync_started && record.sync_end ? millisToMinutesAndSeconds(diffMs) : '--'}</span>
            },
        },
    ];

    return (
        <>
            <Modal
                title={"Sync Report"}
                onCancel={() => setreportModal(false)}
                width={'40%'}
                className='sync-report-popup'
                centered
                style={{ color: "#031742" }}
                open={reportmodal}
                okButtonProps={{ style: { display: 'none' } }}
                cancelButtonProps={{ style: { display: 'none' } }}
            >
                <div className='report-wrap'>
                    {syncDetails ?
                        <>
                            <div className='report-title'>
                                <h3>GSTIN Processed count</h3>
                                <h3>First time sync</h3>
                                <h3>Total notices fetched</h3>
                                <h3>Successfully synced</h3>
                                <h3>Sync failed</h3>
                                <h3>Sync start time</h3>
                                {syncDetails.sync_end && <h3>Sync end time</h3>}
                            </div>
                            <div className='report-cnt'>
                                <h3>: {syncDetails.gst_list.length}</h3>
                                <h3>: {syncDetails.gst_list.filter((sd: any) => sd.is_first_time).length}</h3>
                                <h3>: {syncDetails.gst_list.map((obj: any) => obj.notice_count).reduce((accumulator: any, current: any) => accumulator + current, 0)}</h3>
                                <h3>: {syncDetails.gst_list.filter((sd: any) => sd.sync_end != null && !sd.is_sync_failed).length}</h3>
                                <h3>: {syncDetails.gst_list.filter((sd: any) => sd.sync_end != null && sd.is_sync_failed).length}</h3>
                                <h3>: {new Date(syncDetails.created_at).toDateString() + ' ' + new Date(syncDetails.created_at).toLocaleTimeString()}</h3>
                                {syncDetails.sync_end && <h3>: {syncDetails.sync_end ? new Date(syncDetails.sync_end).toDateString() + ' ' + new Date(syncDetails.sync_end).toLocaleTimeString() : '--'}</h3>}
                            </div>
                        </> :
                        <Spin spinning={syncloading} size='default' style={{ maxWidth: '100%', display: 'flex', position: 'absolute' }}>
                        </Spin>
                    }
                </div>
                <div className='footer_wrap'>
                    <p><b>Note:</b> Incorrect password is not at all considered.</p>
                    {syncDetails?.sync_processed && <Button className='dtail_btn' onClick={() => { setreportModal(false); setDetailopen(true); setDetailsource(syncDetails.gst_list) }}>Detailed Report <ArrowRightIcon /> </Button>}
                </div>
            </Modal >
            <Modal
                title="Sync Details"
                onCancel={() => { setDetailopen(false); setDetailsource([]) }}
                className='upload-popup'
                width={'auto'}
                centered
                style={{ color: "#031742", minWidth: '30%' }}
                open={detailopen}
                okButtonProps={{ style: { display: 'none' } }}
                cancelButtonProps={{ style: { display: 'none' } }}
            >
                <>
                    <div className='table-label-wrap'>
                        <div className='table-label'>
                            <span>Total accounts</span>
                            <h4>{syncDetails?.gst_list?.length}</h4>
                        </div>
                        <div className='table-label'>
                            <span>Sync start time</span>
                            <h4>{syncDetails?.sync_started ? new Date(syncDetails.created_at).toDateString() + ' ' + new Date(syncDetails.created_at).toLocaleTimeString() : '-'}</h4>
                        </div>
                        <div className='table-label'>
                            <span>Sync end time</span>
                            <h4>{syncDetails?.sync_end ? new Date(syncDetails.sync_end).toDateString() + ' ' + new Date(syncDetails.sync_end).toLocaleTimeString() : '-'}</h4>
                        </div>
                    </div>

                    <Table
                        className='details-table'
                        loading={detailloading}
                        style={{ minWidth: '35vw', maxWidth: '100%', marginTop: '3%' }}
                        columns={detailcolumns}
                        dataSource={detailSource}
                    // Other props for Table component
                    />
                </>
            </Modal >
            {
                anchorEl && <div className='Notification_cover fade-out' ref={optionsRef}>
                    <div className='head_box'>
                        <span className='head_line'>Notifications</span>
                        <div className='sub-header-wrap'>
                            {/* <div className='sync-box' onClick={() => popupFunc(progres.data.label)}>
                            {progres.data.loading &&
                                <Button className="Progres-btn" style={{ marginRight: '10px' }}>
                                    <AutorenewIcon className='Progress-icon' />
                                    <b>{progres.data.label} Progressing</b>
                                </Button>
                            }
                        </div> */}
                            <span className='link-item'
                                onClick={() => { getLastSyncStatus(); popupFunc('Sync'); }}
                            >
                                Last Sync Report
                            </span>
                        </div>
                    </div>
                    <div className='notify_content_cover'>
                        <span style={{ color: '#000', textAlign: 'center', marginTop: '3%' }}>No notifications !</span>

                        {/* <div className='content_block'>
                        <Avatar className='avatar'>ZC</Avatar>
                        <div className='content-msg-cover'>
                            <span className='content_msg'>You have one login, from another windows device .</span>
                            <span className='content_stamp'>3 weeks ago</span>
                        </div>
                    </div> */}
                    </div>
                </div >
            }
            <NotifyAlert notifyOpen={notifyOpen} changeStatus={setNotifyOpen} notifyMessage={notifyMessage} notifyType={notifyType}></NotifyAlert>
        </>
    )
}