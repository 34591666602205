// react 
import React from 'react'
import { useCallback, useContext, useEffect, useRef, useState } from 'react'
import CurrencyFormat from 'react-currency-format'
import { Link, useNavigate } from 'react-router-dom'
// ..........

// scss file
import './newDashboard.scss'
// ..........

// package components
import type { CalendarProps } from 'antd'
import { DatePicker as AntDatePicker, Button, Calendar, Checkbox, Dropdown, Form, Input, Menu, message, Modal, Radio, RadioChangeEvent, Select, Spin, Tag, Tooltip, Upload } from 'antd'
import Table from 'antd/es/table'
import dayjs, { Dayjs } from 'dayjs'
import axios from 'axios'
import { TextField } from '@mui/material'
import moment from 'moment'
// ..........

// child components
import { useGlobalState } from '../../Statecontext'
import Chart from '../../components/chart/index'
import { NotifyAlert } from '../../components/notify_alert'
import { ZTable2 } from '../../components/table2'
import { serviceConfig } from '../../core/services'
import { AppContext } from '../../core/utils/Context'
import { Utils } from '../../core/utils/utils'
// ..........

// icons
import attachIcon from '../../assets/images/attach_icon.svg'
import arrowRightIcon from '../../assets/images/chevron-right.svg'
import expandIcon from '../../assets/images/expandIcon.svg'
import expandIcon1 from '../../assets/images/expandIcon2.svg'
import filterIcon from '../../assets/images/filter.svg'
import active_filtericon from '../../assets/images/filter_active_icon.svg'
import gstIcon from '../../assets/images/gstin managed.svg'
import incorrectPasswordIcon from '../../assets/images/incorrect password.svg'
import noticeIcon from '../../assets/images/total notice count.svg'
import closeIcon from '../../assets/register/close.png'
import editIcon from '../../assets/images/editIcon.svg'
import LinkIcon from '@mui/icons-material/Link';
import plusIcon from '../../assets/images/plus.svg';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined'
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined'
import passwordIcon from '../../assets/images/password.svg'
import usernameIcon from '../../assets/images/username.svg';
import wrongPswdIcon from '../../assets/images/wrong_pswd.png'
import passwordDotIcon from '../../assets/images/passwordDot.svg'
import gst_loan from '../../assets/GST-dash/gst_loan_dash_icon.png'
import { downloadServices } from '../../core/services/download'
// ..........

interface CaseData {
	id: string;
	case_type_name: string;
	case_ref_id: string[];
	case_arn_no: string;
	case_description: string[];
	// Add other fields as necessary
}

interface GST {
	gst_no: string;
	trade_name: string;
	state: string;
	// Add other fields as necessary
}

interface ApiResponse {
	id: string;
	gst: GST;
	notice_status: string;
	notice_item_id: string | null;
	issue_date: string;
	due_date: string;
	case_data: CaseData;
	// Add other fields as necessary
	state: string;
	tax_amount: number;
	tax_period: string;
	attachments: any;
	// ...other fields
}

interface FormValues {
	portal_status: string;
	state: string;
	trade_name: string;
	gst_no: string;
	gstin_input: string;
	tax_period: string;
	refid: string;
	caseid: string;
	casetype: string;
	section: string;
	taxamount: number;
	// Add other form values as necessary
}

export const Newdashboard = () => {

	// form values states	
	const [States, setStates] = useState([]);
	const [Gst_in, setGstin] = useState([])
	const [managegstdetails, setManagegstdetails] = useState([])
	const [noticeObj, setNoticeObj] = useState(false);
	const [selectedDate, setSelectedDate] = useState<Dayjs | undefined>(undefined);
	const [calendardata, setCalendarData] = useState<any>([])
	const [password_type, setPasswordType] = useState('password')
	const [form] = Form.useForm();
	const { formValues, handleChange, handleInitial } = useContext(AppContext)
	const [tabActive, setTabActive] = useState('form')
	const { user_name, password, otp } = formValues;
	const [uploadedfile, setUploadedFile] = useState<any>();
	// ..........

	// alerts and modal states
	const [notifyOpen, setNotifyOpen] = useState(false)
	const [notifyType, setNotifyType] = useState<any>()
	const [notifyMessage, setNotifyMessage] = useState('')
	var [tableHeader, setTableHeader] = useState<any>()
	const [modal2Open, setModal2Open] = useState(false);
	const [addGstLoadModal, setAddGstLoadModal] = useState(false)
	const [multimodal, setMultimodal] = useState(false)
	const [uploadcomplete, setUploadcomplete] = useState(true);
	const [uploading, setUploading] = useState(false)
	const [noticeStatus, setNoticeStatus] = useState('Action to be taken');
	// ..........

	// loading state
	const [loading, setLoading] = useState(false)
	const [updateloading, setUpdateLoading] = useState(false)
	const [formloading, setFormloading] = useState(false);
	const [calendarloading, setCalendarloading] = useState(false)
	// ..........

	// limit & filter states

	const {  setDashbordarticle, action, setAction, setTab, setMenuclick, setTabactive, setProgres, setBreadcrumbPath, setManageactive, setIsManagegst, masterData, setMasterData, filterQuery, setFilterQuery, setNoticeQuery, sorterQuery, searchquery, setSearchquery, setSorterQuery, chartquery, gstSyncModal, setGstSyncModal, setTotalpages, page, setPage, setRowsPerPage, tabactive, rowsPerPage, setStatuschange, selectedGstRows, setSelectedGstRows, setAddnotice } = useGlobalState();
	// ..........

	//  datas states
	const [gstCounts, setGstCounts] = useState<any>()
	const [ticketcounts, setTicketCounts] = useState<any>()
	const [loandetails, setLoandetails] = useState<any>()
	const [noticeData, setNoticeData] = useState<any>([]);
	const [updatesList, setUpdateslist] = useState<any>([])
	const [detailSource, setDetailsource] = useState<any>([])
	// ..........

	// reference states
	const { ticket, MultiNoticeUpdate } = Utils();
	const [isMobile, setIsMobile] = useState<boolean>(false);
	const abortControllerRef = useRef<AbortController | null>(null);
	const key = 'updatable';
	const [messageApi] = message.useMessage();
	const userData = JSON.parse(localStorage.getItem('userData') || '{}')
	// ..........

	// data get function
	const getGSTCounts = useCallback(() => {
		serviceConfig.get("get_gst_counts", true, null, null).then((data: any) => {
			setGstCounts(data.data)
		}, (err: any) => {
			console.log(err)
		})
	}, [action])

	const getLoandetails = () => {
		serviceConfig.get("get_loan_details", true, { user_id: userData.id }, null).then((data: any) => {
			setLoandetails(data)
			console.log(data)
		}, (err: any) => {
			console.log(err)
		})
	}

	const ticketCounts = () => {
		serviceConfig.get("dashboard_ticket_counts", true, null, null).then((data: any) => {
			setTicketCounts(data.data)
		}, (err: any) => {
			console.log(err)
		})
	}

	const loadmanagegstdata = () => {
		serviceConfig.get("manageGst", true, null, null).then((data: any) => {
			setManagegstdetails(data)
			// const names =
			// 	data.filter((item: any) => item.trade_name !== null)
			// 		.map((item: any) => item.trade_name as string);
			// setTradename(names);
			const gsts = data
				.filter((item: any) => item.gst_no !== null)
				.map((item: any) => item.gst_no);
			setGstin(gsts);
		})
			.catch((error: any) => { console.log(error) })
	}

	const get_latest_article = useCallback(() => {
		if (abortControllerRef.current) {
			abortControllerRef.current.abort();
		}

		// Create a new AbortController for the new request
		const controller = new AbortController();
		abortControllerRef.current = controller;
		const signal = controller.signal;

		let query: any = {}
		setUpdateLoading(true)
		serviceConfig.get("latest_updates", true, query, null, signal).then((data: any) => {
			setUpdateLoading(false)
			setUpdateslist(data?.data || [])
		}, (err: any) => {
			setUpdateLoading(false)
			setNotifyType("error")
			setNotifyMessage(err.response && err.response.data || 'Something Went Wrong !')
			setNotifyOpen(true)
		})

		return () => {
			controller.abort();
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	useEffect(() => {
		getGSTCounts()
		getLoandetails()
		ticketCounts()
		loadmanagegstdata();
		loadcalendardetails()
		get_latest_article()
	}, [action])


	useEffect(() => {
		const mediaQuery = window.matchMedia('(max-width: 768px)'); // Adjust the max-width according to your mobile breakpoint
		setIsMobile(mediaQuery.matches);

		const handleResize = () => setIsMobile(mediaQuery.matches);

		mediaQuery.addListener(handleResize);

		return () => {
			mediaQuery.removeListener(handleResize);
		};
	}, []);

	// ...........

	// add gst option
	// Check if all values are not empty and if there are some errors
	const isError = useCallback(
		() =>
			Object.keys({ user_name, password }).some(
				(name) => (formValues[name].required && !formValues[name].value) || formValues[name].error
			),
		[formValues, user_name, password]
	)

	const actionGst = () => {
		setFormloading(true)
		setModal2Open(false)
		setAddGstLoadModal(true)

		setProgres({ data: { loading: true, label: 'Upload' } })

		const addformdata = { user_name: formValues.user_name.value, password: formValues.password.value }

		serviceConfig.post("manageGst", true, { ...addformdata, id: '' }, false, false, true)
			.then((res: any) => {
				console.log(res, 'respones')
				getGSTCounts()
				if (res.id) {
					setProgres({ data: { loading: false, label: '' } })
					// getFileUploadStatus()
					setFormloading(false)
					setNotifyType("success")
					setNotifyMessage("GST details added successfully.")
					setNotifyOpen(true)
					loadmanagegstdata()
					handleInitial()
					setAddGstLoadModal(false)
					setAction(true)
					setPasswordType('password')
				} else {
					if (res.message) {
						setProgres({ data: { loading: false, label: '' } })
						const { msg, ...rest } = res.data;
						setFormloading(false)
						setNotifyType("warning")
						setNotifyMessage("Enter your OTP.")
						setNotifyOpen(true)
						setAddGstLoadModal(false)
					}
				}
			}, (err: any) => {
				setProgres({ data: { loading: false, label: '' } })
				console.log(err, 'error accured')
				setNotifyType("error")
				if (err.response && err.response.data) {
					setNotifyMessage(err.response.data);
				} else {
					setNotifyMessage("An error occurred");
				}
				setNotifyOpen(true)
				setFormloading(false)
				setAddGstLoadModal(false)
				setPasswordType('password')
			})
		handleInitial()
	}
	// ........

	// Flatten Object Utility
	const flattenObject = (ob: any): Record<string, any> => {
		let toReturn: Record<string, any> = {};

		for (let i in ob) {
			if (!ob.hasOwnProperty(i)) continue;

			if ((typeof ob[i]) === 'object' && ob[i] !== null) {
				let flatObject = flattenObject(ob[i]);
				for (let x in flatObject) {
					if (!flatObject.hasOwnProperty(x)) continue;

					toReturn[i + '.' + x] = flatObject[x];
				}
			} else {
				toReturn[i] = ob[i];
			}
		}
		return toReturn;
	};
	// ...........

	// search 
	const search = (value: any) => {
		if (noticeData[0].notice) {
			setNoticeObj(true);
		}
		const filterTable = noticeData.filter((o: any) =>
			Object.keys(noticeObj ? o.notice : o).some(k => {
				if (k !== 'gst' && k !== 'case_data') {
					return String(noticeObj ? o.notice[k] : o[k])
						.toLowerCase()
						.includes(value.toLowerCase())
				} else {
					return Object.keys(noticeObj ? o.notice[k] : o[k]).some(j => String(noticeObj ? o.notice[k][j] : o[k][j])
						.toLowerCase()
						.includes(value.toLowerCase()))
				}
			}
			)
		);

		setNoticeData(filterTable);
	};
	// .............

	// counts card array
	const gstCountsData = {
		latest_notice: gstCounts && gstCounts.latest_notices_count || 0,
		action_taken: gstCounts && gstCounts.action_taken || 0,
		action_to_be_taken: gstCounts && gstCounts.action_to_be_taken || 0,
		over_due: gstCounts && gstCounts.overdue || 0,
		demand_amount: gstCounts && gstCounts.total_demand_amount || 0,

		raised_ticket: ticketcounts && ticketcounts.raised_tickets_counts || 0,
		inprogress_ticket: ticketcounts && ticketcounts.open_tickets || 0,
		pending_ticket: ticketcounts && ticketcounts.pending_tickets || 0,

		total_gst: gstCounts && gstCounts.total_gst || 0,
		wrong_gst: gstCounts && gstCounts.wrong_gst || 0,
		total_notices: gstCounts && gstCounts.total_notices || 0,
		seven_days_due: gstCounts && gstCounts.seven_days_due || 0,
	};
	const formatter = new Intl.NumberFormat('en-IN');
	const cards = [
		{
			id: 1,
			name: 'Latest Notices',
			theme: '#BEDCE7',
			font: '#5FAABC',
			img: require('../../assets/GST-dash/GSTIN.png'),
			count: gstCountsData.latest_notice,
			path: '/home/allnotice'
		},
		{
			id: 2,
			name: 'All Notices',
			theme: '#BEDCE7',
			font: '#5FAABC',
			img: require('../../assets/GST-dash/Incorrect_pass.png'),
			count: gstCountsData.total_notices,
			path: '/home/allnotice'
		},
		{
			id: 3,
			name: 'Action Taken',
			theme: '#EEDEC1',
			font: '#FDB23C',
			img: require('../../assets/GST-dash/Totalnotice.png'),
			count: gstCountsData.action_taken,
			path: '/home/allnotice'
		},
		{
			id: 4,
			name: 'Action to be Taken',
			theme: '#EEDEC1',
			font: '#FDB23C',
			img: require('../../assets/GST-dash/Open_notice.png'),
			count: gstCountsData.action_to_be_taken,
			path: '/home/allnotice'
		},
		{
			id: 5,
			name: 'Overdue',
			theme: '#ACBDD8',
			font: '#20438A',
			img: require('../../assets/GST-dash/over-Due.png'),
			count: gstCountsData.over_due,
			path: '/home/allnotice'
		},
		{
			id: 6,
			name: 'Demand Outstanding',
			theme: '#ACBDD8',
			font: '#20438A',
			img: require('../../assets/GST-dash/7daydue.png'),
			count: '₹ ' + formatter.format(Number(gstCountsData.demand_amount.toFixed(2)))
			// path: '/home/due-dashboard'
		}

	]

	const counts: any = [
		{
			manage: [
				{
					label: 'GSTIN wise Notices',
					value: gstCountsData.total_gst,
					path: '/home/allgstin'
				},
				{
					label: 'Incorrect Password',
					value: gstCountsData.wrong_gst,
					path: '/home/allgstin'
				}
			],
			solutions: [
				{
					label: 'Raised',
					value: gstCountsData.raised_ticket,
					path: '/home/solution-dashboard'
				},
				{
					label: 'Inprogress',
					value: gstCountsData.inprogress_ticket,
					path: '/home/solution-dashboard'
				},
				{
					label: 'Pending with Department',
					value: gstCountsData.pending_ticket,
					path: '/home/solution-dashboard'
				}
			],
			gst_loan: [
				{
					label: 'Applied',
					value: loandetails ? loandetails.applied : '-',
					path: '/home/gst-loan'
				},
				{
					label: 'Application Status',
					value: loandetails ? loandetails.application_Status : '-',
					path: '/home/gst-loan'
				},
				{
					label: 'Sanction Status',
					value: loandetails ? loandetails.sanctioned : '-',
					path: '/home/gst-loan'
				}
			]
		}
	]

	// ...........

	const cardnavigation = (card: any) => {
		setSearchquery('')
		setFilterQuery({})
		setPage(1);
		setTotalpages(0);
		setIsManagegst(false)
		setRowsPerPage(10);
		navigate(card.path)
		if (card.name === 'Incorrect Password') {
			setManageactive('Incorrect')
			// let query: any = {
			// 	wrong_password: [true]
			// };
			// setFilterQuery(query);
		} else if (card.name === 'Latest Notices') {
			setMenuclick('Latest notices')
			setTabactive('Latest')
			let updatedQuery: any = {}
			updatedQuery['is_over_due'] = false
			setNoticeQuery(updatedQuery)
			setBreadcrumbPath(`home/notices/${card.name}`);
		} else if (card.name === 'All Notices') {
			setMenuclick('All notices')
			setTabactive('Additional')
			let updatedQuery: any = {}
			updatedQuery['is_over_due'] = false
			setNoticeQuery(updatedQuery)
			setBreadcrumbPath(`home/notices/all notices`);
		} else if (card.name === 'Action to be Taken' || card.name === 'Action Taken') {
			let updatedQuery: any = {}
			if (card.name === 'Action to be Taken') {
				setMenuclick('Action to be taken')
				updatedQuery['notice_status'] = ['Action to be taken']
			}
			if (card.name === 'Action Taken') {
				setMenuclick('Action taken')
				updatedQuery['notice_status'] = ['Action taken']
			}
			setTabactive('Additional')
			updatedQuery['is_over_due'] = false
			setNoticeQuery(updatedQuery)
			setBreadcrumbPath(`home/notices/${card.name}`);
		} else if (card.name === 'Overdue') {
			setMenuclick(card.name)
			setTabactive('Additional')
			let updatedQuery: any = {}
			updatedQuery['is_over_due'] = true
			setNoticeQuery(updatedQuery)
			setBreadcrumbPath(`home/notices/${card.name}`);
		}

	}

	const submenuclick = (item: any) => {
		setSearchquery('')
		setFilterQuery({})
		setPage(1);
		setTotalpages(0);

		if (item.label === 'Incorrect Password' && item.value > 0) {
			setManageactive('Incorrect')
			setBreadcrumbPath(`home/notices/incorrect password`);
			navigate(item.path)
		} else if (item.label === 'GSTIN wise Notices' && item.value > 0) {
			setManageactive('All')
			setBreadcrumbPath(`home/notices/GSTIN wise Notices`);
			setTabactive('GSTIN Summary')
			navigate(item.path)
		} else {
			setBreadcrumbPath(item.path)
			navigate(item.path)
		}
	}
	// .........

	// due calendar
	const loadcalendardetails = useCallback((search_data?: any) => {
		setCalendarloading(true)
		let query: any = {
			by_page: true,
			page: page,
			page_size: rowsPerPage
		}
		if (searchquery) {
			query['search'] = searchquery;
		}

		if (filterQuery.due_from_date && filterQuery.due_to_date) {
			query['notice_start_due_date'] = filterQuery.due_from_date
			query['notice_end_due_date'] = filterQuery.due_to_date
		}

		if (filterQuery.gst_no) {
			query['gst_no'] = filterQuery.gst_no.join(',')
		}
		if (filterQuery.state) {
			query['state'] = filterQuery.state.join(',')
		}
		if (filterQuery.due_day) {
			query['due_day'] = filterQuery.due_day.join(',')
		}
		if (filterQuery.trade_name) {
			query['trade_name'] = filterQuery.trade_name.join(',')
		}
		if (filterQuery.tradename) {
			query['trade_name'] = filterQuery.tradename.join(',')
		}
		if (filterQuery.tax_period && filterQuery.tax_period.length > 0) {
			query['tax_period'] = filterQuery.tax_period.join(',')
		}
		if (sorterQuery && sorterQuery.columnKey) {
			query['sort'] = `${sorterQuery.columnKey},${sorterQuery.order === 'ascend' ? 'asc' : 'desc'}`
		}

		query['is_due'] = true

		serviceConfig.get("get_notices", true, query, null).then((data: any) => {
			setCalendarData(data.results || data.data)
			setCalendarloading(false)
		}, (err: any) => {
			setCalendarloading(false)
			console.log(err)
			if (err.message === "Request failed with status code 401") {
				navigate('/login')
				localStorage.clear()
				window.location.reload();
			}
		})
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	const arrayOfObjects: any = calendardata;

	const generateSwitchCases = (datesArray: any): any => {
		const switchCases: any = {};
		datesArray.forEach((item: any) => {
			const formattedDate = moment(item.due_date).format('DD-MM-YYYY');
			if (formattedDate in switchCases) {
				switchCases[formattedDate].push({ type: 'success', content: `${item.case_data.case_arn_no}`, gstid: `${item.id}` })
			} else {
				switchCases[formattedDate] = [
					{ type: 'success', content: `${item.case_data.case_arn_no}`, gstid: `${item.id}` },
				];
			}
		});


		const defaultCase = [
			{ type: 'warn', content: 'No Due.' }
		];

		switchCases.default = defaultCase;

		return switchCases;
	};

	const switchCases = generateSwitchCases(arrayOfObjects);

	const getListData = (value: any): any[] => {
		const date = new Date(value.$d); // Assuming value is of type DateObject
		const formattedDate = moment(date).format('DD-MM-YYYY');
		const listData = switchCases[formattedDate] || [];
		return listData;
	};

	const getMonthData = (value: Dayjs) => {
		if (value.month() === 8) {
			return 1394;
		}
	};

	const monthCellRender = (value: Dayjs) => {
		const num = getMonthData(value);
		return num ? (
			<div className="notes-month">
				<section>{num}</section>
				<span>Backlog number</span>
			</div>
		) : null;
	};

	const navigate = useNavigate();
	const handleCellClick = (idvalue: any, value: any) => {
		const date = new Date(value.$d); // Assuming value is of type DateObject
		const formattedDate = moment(date).format('YYYY-MM-DD');
		navigate(`/home/${idvalue}/view-solution-details`)
		let query: any = {
			by_page: true,
			page: page,
			page_size: rowsPerPage
		}
		query['is_due'] = true;
		query['notice_start_due_date'] = formattedDate
		query['notice_end_due_date'] = formattedDate
		setNoticeQuery(query)
	};

	const dateCellRender = (value: Dayjs) => {
		const listData: any = getListData(value);
		const hasBadge = listData.length > 0;
		const count = listData.length;
		return (
			<>
				{hasBadge && (
					<Tooltip
						placement="topLeft"
						title={listData.map((item: any) => item.content).join(', ')}
						arrow={true}
						color={'#E96919'}
					>
						<span className="ant-picker-cell-inner" style={{ backgroundColor: '#E96919', color: '#fff', position: "absolute", top: 0, left: 0 }} onClick={() => handleCellClick(listData[0].gstid, value)}>
							{value.date()}
						</span>
					</Tooltip>
				)
				}
			</>
		);
	};

	const cellRender: CalendarProps<Dayjs>['cellRender'] = (current, info) => {
		if (info.type === 'date') return dateCellRender(current);
		if (info.type === 'month') return monthCellRender(current);
		return info.originNode;
	};
	// ....... 


	const add_opt_submenu = [
		{
			items: [
				{ name: 'Add GSTIN', openstate: setModal2Open },
				{ name: 'Add Multiple GSTIN', openstate: setMultimodal },
			]
		}
	]
	const renderAddMenu = (submenu: any[] | undefined, index: number) => (
		<Menu
			style={{ display: submenu ? '' : 'none' }}
			className="horizontal-grouped-menu"
		>
			{submenu?.[0]?.items.map((item: any) => (
				<Menu.Item key={item.name} onClick={() => submenuClick(item)}>
					{item.name}
				</Menu.Item>
			))}
		</Menu>
	);

	const submenuClick = (item: any) => {
		item.openstate(true)
	}
	// .........


	// form modal function
	const handlecloseuploadpopup = () => {
		setMultimodal(false)
		setFormloading(false)
		setUploadedFile(null)
		setTabActive('form');
	}
	// .....

	//.manage gst multiple add gst
	const handleUpload = (info: any) => {
		setUploadedFile(info.file.originFileObj)

		if (info.file.status === 'done') {
			setUploadedFile(info.file)
		} else if (info.file.status === 'error') {
			console.log(`${info.file.name} file upload failed.`);
		}
	};

	const handleDragEnter = (e: React.DragEvent) => {
		e.preventDefault();
		const files: any = e.dataTransfer.files[0];
		// setUploadedFile(files)
		if (files && files.length > 0) {

			if (files.type === 'application/vnd.ms-excel' || files.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
				setUploadedFile(files);
			} else {
				setNotifyOpen(true)
				setNotifyType("error")
				setNotifyMessage('Please select a valid csv or xslx file.');
				// alert('Please select a valid csv or xslx file.');
				setUploadedFile(null)
			}
		} else {
			setUploadedFile(null);
		}
	};

	const handleDragLeave = () => {
	};

	const handleDrop = (e: React.DragEvent) => {
		e.preventDefault();

		const files: any = e.dataTransfer.files[0];
		// setUploadedFile(files)
		if (files && files.length > 0) {

			if (files.type === 'application/vnd.ms-excel' || files.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
				setUploadedFile(files);
			} else {
				setNotifyOpen(true)
				setNotifyType("error")
				setNotifyMessage('Please select a valid csv or xslx file.');
				// alert('Please select a valid csv or xslx file.');
				setUploadedFile(null)
			}
		} else {
			setUploadedFile(null);
		}
	};

	const areAllValuesEqual = (array: any, key: any) => array.every((obj: { [x: string]: any; }) => obj[key] === "Completed");

	const getFileuploadstatus = () => {
		setUploading(true)
		var start_autoload: any = setInterval(() => {
			serviceConfig.get('gst_upload_file_status', true, null, null)
				.then((data: any) => {
					if (data && data.length > 0 && data[0].is_completed) {
						clearInterval(start_autoload)
						start_autoload = null;
						setProgres({ data: { loading: false, label: '' } })
						setUploadcomplete(data[0].is_completed)
						setDetailsource(data)
						handletabClick('table')
						setUploading(false)
						setMultimodal(true);
					}
				}, (err: any) => {
					clearInterval(start_autoload)
					start_autoload = null;
					setProgres({ data: { loading: false, label: '' } })
					setUploading(false)
				})
		}, 10000);
	}

	const UploadFile = () => {
		setFormloading(true)
		let file_data = uploadedfile.hasOwnProperty('originFileObj') ? uploadedfile.originFileObj : uploadedfile
		serviceConfig.upload("upload_file", null, file_data)
			.then((data: any) => {
				setAction(true)
				setFormloading(false)
				setNotifyOpen(true)
				setNotifyType(data.data)
				setNotifyMessage(data.message);
				setUploadedFile(null)
				setMultimodal(false)
				setUploadedFile(null)
				openMessage()
				setProgres({ data: { loading: true, label: 'Upload' } })
				getFileuploadstatus()
			}).catch((err: any) => {
				setUploadedFile(null)
				setFormloading(false)
				setNotifyOpen(true)
				setNotifyType("error")
				setNotifyMessage(err.response.data);
			})
	}

	const handletabClick = (label: any) => {
		setTabActive(label);
	}
	// ..............

	// message open function
	const openMessage = () => {
		messageApi.open({
			key,
			type: 'loading',
			content: 'Loading...',
		});
		setTimeout(() => {
			messageApi.open({
				key,
				type: 'success',
				content: 'Add Multiple GSTIN sync started successfully!',
				duration: 2,
			});
		}, 1000);
	}
	// ..............

	// compliance nav funcion
	const compliancenav = (tab: any) => {
		setTab(tab);
		navigate('/home/compliance')
	}

	const detailcolumns = [
		{
			title: 'S.No',
			dataIndex: 'serial',
			key: 'serial',
			render: (text: any, record: any, index: number) => index + 1,
		},
		{
			title: 'User Name',
			dataIndex: 'user_name',
			key: 'user_name',
		},
		{
			title: 'Password',
			dataIndex: 'password',
			key: 'password',
			render(value: any, record: any, index: any) {
				if (!record.wrong_password) {
					return <img src={passwordDotIcon} alt='password icon' />
				}
				else {
					return <Tooltip title={'Given credentials mismatched!'}><img src={wrongPswdIcon} alt='password icon' /></Tooltip>
				}
			}
		},
		{
			title: 'Status',
			dataIndex: 'status',
			key: 'status',
		},
	];

	return (
		<>
			< div id="gst-parent-container" className="gst-container" >

				<Modal
					title={""}
					onCancel={() => setGstSyncModal(false)}
					width={'35%'}
					className='action-popup'
					centered
					style={{ color: "#031742" }}
					open={gstSyncModal}
					okButtonProps={{ style: { display: 'none' } }}
					cancelButtonProps={{ style: { display: 'none' } }}
				>
					<div>
						<b>Sync scheduled successfully</b>
						<p>You can minimize this tab or even close the website, and return later to check the process. You're all set!</p>
					</div>
				</Modal>
				<Modal
					title={""}
					onCancel={() => setAddGstLoadModal(false)}
					width={'35%'}
					className='action-popup'
					centered
					style={{ color: "#031742" }}
					open={addGstLoadModal}
					okButtonProps={{ style: { display: 'none' } }}
					cancelButtonProps={{ style: { display: 'none' } }}
				>
					<div>
						<b>Adding GSTIN in progress</b>
						<p>Adding GSTIN may take 30 seconds. Please wait. You may close this dialog box.</p>
					</div>
				</Modal>
				<Modal
					title="ADD GSTIN"
					onCancel={() => { setModal2Open(false); setFormloading(false); handleInitial(); setPasswordType('password') }}
					width={'30%'}
					className='action-popup'
					centered
					style={{ color: "#031742" }}
					open={modal2Open}
					okButtonProps={{ style: { display: 'none' } }}
					cancelButtonProps={{ style: { display: 'none' } }}
				>

					<Form form={form} name="validateOnly" layout="vertical" autoComplete="off">
						<div className="col-sm-12 input-group" style={{ marginTop: '1rem' }}>
							<label className="label">User id</label>
							<div className="input-box" >
								<img className="icons" src={usernameIcon} alt='usernameIcon'></img>
								<input
									type="text"
									name='user_name'
									value={user_name.value}
									onBlur={handleChange}
									onChange={handleChange}
									placeholder="Enter your portal user id"></input>
								{user_name.error && <p className="gst-err-msg" >{user_name.error}</p>}
							</div>
						</div>
						<div className="col-sm-12 input-group" style={{ marginTop: '1rem' }}>
							<label className="label">Password</label>
							<div className="input-box" >
								<img className="icons" src={passwordIcon} alt='password_icon'></img>
								<input
									type={password_type}
									name='password'
									value={password.value}
									onBlur={handleChange}
									onChange={handleChange}
									placeholder="Enter your password"></input>
								{password_type === 'password' ? <VisibilityOutlinedIcon onClick={() => setPasswordType('text')} className='eye_icon' /> : <VisibilityOffOutlinedIcon onClick={() => setPasswordType('password')} className='eye_icon' />}
								{password.error && <p className="gst-err-msg">{password.error}</p>}
							</div>
						</div>

						<Button
							htmlType="submit"
							className='popup-button'
							loading={formloading}
							disabled={isError()}
							style={{ background: "#424B70", color: "white", border: "none", width: "80%", height: "6vh", marginTop: '1.5rem', left: '10%' }}
							onClick={() => {
								actionGst()
							}}>
							{!formloading && <>Add & GET DETAILS FROM GSTIN</>}
							{formloading && <>Loading...</>}
						</Button>

					</Form>
				</Modal>
				<Modal
					title="Multiple GSTIN"
					onCancel={handlecloseuploadpopup}
					className='upload-popup'
					width={'auto'}
					centered
					style={{ color: "#031742", minWidth: '30%' }}
					open={multimodal}
					okButtonProps={{ style: { display: 'none' } }}
					cancelButtonProps={{ style: { display: 'none' } }}
				>
					<div className="detail-view-tab-cover-container">
						<div className='detail-view-tab-cover'>
							<span className={tabActive === 'form' ? 'view-tab one active-tab' : 'view-tab one'} onClick={() => handletabClick('form')}>Upload</span>
							<span className={tabActive === 'table' ? 'view-tab two active-tab' : 'view-tab two'} onClick={() => { handletabClick('table'); getFileuploadstatus() }}>Details</span>
						</div>
					</div>
					{tabActive === 'form' ?
						<Form form={form} name="validateOnly" layout="vertical" autoComplete="off">

							<Upload
								action="/your-upload-api-endpoint"
								onChange={handleUpload}
								style={{ width: '100%' }}
								accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
								showUploadList={false} // Hide the list of uploaded files
							>
								<div
									className='upload_box'
									onDragEnter={handleDragEnter}
									onDragOver={handleDragEnter}
									onDragLeave={handleDragLeave}
									onDrop={handleDrop}>
									<input
										type="file"
										accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
										style={{ display: 'none' }}
										onChange={handleUpload}
									/>
									{uploadedfile ?
										<p className='selected-file-name'>
											<b>Selected File: </b>{uploadedfile.name}</p> :
										<p>
											Drag & Drop files here or click to select<br></br>
											(CSV or XLSX (.csv or .xlsx)) file only allowed)
										</p>}
								</div>
							</Upload>

							<div className='downlod-template-wrap'>
								<p className='download-link' onClick={() => downloadServices.gst_template()}>Download Template</p>
							</div>

							<Form.Item>
								<Button
									htmlType="submit"
									className='popup-button upload-pop-btn'
									loading={formloading}
									disabled={!uploadedfile || !uploadcomplete}
									style={{
										background: "#424B70",
										color: "white",
										border: "none",
										width: "80%",
										height: "6vh",
										marginTop: '1.5rem',
										left: '10%'
									}}
									onClick={() => {
										UploadFile()
									}}>
									{!formloading && <>{uploadcomplete ? 'Upload' : 'Upload Inprogressing'}</>}
									{formloading && <>Loading...</>}
								</Button>
							</Form.Item>

						</Form> :
						<>
							<div className='table-label-wrap'>
								<div className='table-label'>
									<span>File name</span>
									<h4>{detailSource.length > 0 ? detailSource[0].file_name : '-'}</h4>
								</div>
								<div className='table-label'>
									<span>Total count</span>
									<h4>{detailSource.length > 0 ? detailSource[0].total_count : '-'}</h4>
								</div>
								<div className='table-label'>
									<span>Success count</span>
									<h4>{detailSource.length > 0 ? detailSource[0].success_count : '-'}</h4>
								</div>
								<div className='table-label'>
									<span>Error count</span>
									<h4>{detailSource.length > 0 ? detailSource[0].error_count : '-'}</h4>
								</div>
							</div>
							<Table
								className='details-table'
								loading={uploading}
								style={{ minWidth: '35vw', maxWidth: '100%', marginTop: '3%' }}
								columns={detailcolumns}
								dataSource={detailSource.length > 0 ? detailSource[0]?.gst_list.filter((it: any) => it.is_error) : []}
							// Other props for Table component
							/>
						</>
					}
				</Modal >

				<div className='gst-stats'>
					<div className='count-card-wrap'>
						{cards.map((card) => (
							<div key={card.id}
								onClick={() => { card.count > 0 && cardnavigation(card) }}
								className="count-card" style={{ backgroundColor: '#fff' }}>
								<img src={card.img} alt='card-img' />
								<div className='card-content'>
									<h3 className='label'>{card.name}</h3>
									<span className='value'>{card.count ? card.count : '0'}</span>
								</div>
							</div>
						))}
					</div>

				</div>

				<div className='gstin-wrap'>
					<div className="stats-box" >
						<div className='title_wrap'>
							<span className='title_line'>GSTIN</span>
							<Dropdown overlay={renderAddMenu(add_opt_submenu, 0)} >
								<Button className='gst_add_btn' onClick={() => { setModal2Open(true) }}>
									<img src={plusIcon} alt='plus icon' />
								</Button>
							</Dropdown>
						</div>
						{counts[0].manage.map((item: any) => (
							< div className='count_content' key={item.label} onClick={() => { item.value > 0 && submenuclick(item) }}>
								<span className='count_label'>{item.label}</span>
								<span className='count_val'>{item.value}</span>
							</div>))
						}
					</div>
				</div>

				<div className='mid_content_wrap' style={{}}>
					<div className='solution-wrap'>
						<div className="stats-box" >
							<span className='title_line'>SOLUTIONS</span>
							{counts[0].solutions.map((item: any) => (
								< div className='count_content' key={item.label} onClick={() => { submenuclick(item) }}>
									<span className='count_label'>{item.label}</span>
									<span className='count_val'>{item.value}</span>
								</div>))
							}
						</div>
					</div>
					<div className='loan-wrap'>
						<div className="stats-box" >
							<span className='title_line'>GST LOAN</span>
							{counts[0].gst_loan[0].value === 'yes' ? counts[0].gst_loan.map((item: any) => (
								<div className='count_content'
									key={item.label}
									onClick={() => { submenuclick(item) }}>
									<span className='count_label'>{item.label}</span>
									<span className='count_val'>
										{item.value.charAt(0).toUpperCase() + item.value.slice(1).toLowerCase()}
									</span>
								</div>)) :
								<div className='tab_wrap1' onClick={() => { navigate('/home/gst-loan') }}>
									<span>Get GST Loan</span>
								</div>
							}
						</div>
					</div>
					<div className='latest-updates-cover'>
						<span className='title_line'>LATEST UPDATES</span>
						<div className='update_content'>
							{updateloading ?
								<Spin spinning={true} size='default' style={{ display: 'flex', height: '100%', width: '100%', alignItems: 'center', justifyContent: 'center' }}>
								</Spin> :
								<>
									{ updatesList?.length !== 0 ? updatesList?.map((item: any, index: any) => (
										<div className='content_row' key={index}>
											<span className='content_subject' onClick={() => { navigate('/home/latestupdates'); setDashbordarticle(item) }}><span className='s_no'>{index + 1}.</span> {item.title}</span>
											{/* <span className='content_desc'
												onClick={() => {
													item.link && window.open(item.link, '_blank');
												}}>{item.title}</span> */}
										</div>)) :
										<span style={{ color: 'rgba(0, 0, 0, 0.45)', margin: 'auto' }}>No data</span>
									}
								</>}
						</div>
					</div>
				</div>
				<div className='right_content_wrap' style={{}}>
					<div className="calendar-wrap">
						{calendarloading ?
							<Spin spinning={true} size='default' style={{ display: 'flex', height: '100%', width: '100%', alignItems: 'center', justifyContent: 'center' }}>
							</Spin> :
							<Calendar
								onSelect={(date: Dayjs | undefined) => setSelectedDate(date)}
								className='calendar'
								cellRender={cellRender}
								fullscreen={false}
								value={selectedDate}
							/>
						}
					</div>
					<div className='compliance_wrap'>
						<div className="stats-box" >
							<span className='title_line'>COMPLIANCE</span>
							<div className='tab_wrap' onClick={() => { compliancenav('GSTR 1') }}>
								<span>GSTR 1</span>
							</div>
							<div className='tab_wrap1' onClick={() => { compliancenav('GSTR 3B') }}>
								<span>GSTR 3B</span>
							</div>
						</div>
					</div>
				</div>
				<NotifyAlert notifyOpen={notifyOpen} changeStatus={setNotifyOpen} notifyMessage={notifyMessage} notifyType={notifyType}></NotifyAlert>
			</div >
		</>
	)
}