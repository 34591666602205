// react 
import { useCallback, useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom';
// ..........

// scss file
import './compliance.scss'
// ..........

// package components
// ..........

// child components
import { useGlobalState } from '../../Statecontext';
// ..........

// icons
import searchIcon from '../../assets/images/search.svg';
import { Button, Input, Pagination, Radio, RadioChangeEvent, Select, Spin } from 'antd';
import EditIcon from '@mui/icons-material/Edit';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import VisibilityIcon from '@mui/icons-material/Visibility';
import FilterListIcon from '@mui/icons-material/FilterList';
import TableViewIcon from '@mui/icons-material/TableView';
import uploadIcon from '../../assets/images/Vector.svg';
import downloadIcon from '../../assets/images/dowload-icon.svg';
import EastIcon from '@mui/icons-material/East';
import expandIcon from '../../assets/images/expandIcon.svg';
import expandIcon1 from '../../assets/images/expandIcon2.svg';
import plusIcon from '../../assets/images/plus.svg';
import synctheme from '../../assets/images/sync__all.svg';
import syncWhite from '../../assets/images/sync_now.svg';
import { downloadServices } from '../../core/services/download';
import { serviceConfig } from '../../core/services';
import StatusTable from './Statustable';
import Breadcrumbs from '../../shared/Breadcrumb';
// ..........

interface ReturnData {
    fy: string;
    taxp: string;
    status: string;
}

export const Compliance = () => {

    // form values states
    const [userRole, setUserRole] = useState<any>()
    const [noticeObj, setNoticeObj] = useState(false);
    const [localsearch, setLocalsearch] = useState('')
    const [selected, setSelected] = useState('Select Action')
    const [filterStatus, setFilterStatus] = useState('All');
    const [filters, setFilters] = useState<{ [key: string]: string }>({});
    const [dropdownOpen, setDropdownOpen] = useState<boolean>(false);
    // ..........

    // alerts and modal states
    // ..........

    // loading state
    const [loading, setLoading] = useState(false);
    // ..........

    // limit & filter states
    const [filter, setFilter] = useState(false)
    const [filterList, setFilterList] = useState<any>([])
    const [secStateFilter, setSecStateFilter] = useState<any>({ state: [], arn_nos: [], case_ids: [], section: [] })
    const [checkedItems, setCheckedItems] = useState<string[]>([]);
    const [secFilterCase, setSecFilterCase] = useState('')
    const [checkedArnItems, setCheckedArnItems] = useState<string[]>([])
    const [checkedCaseIdItems, setCheckedCaseIdItems] = useState<string[]>([])
    const [checkedSessionItems, setCheckedSessionItems] = useState<string[]>([])
    const [secondaryFilter, setSecondaryFilter] = useState<any>([])
    const { tab, setTab, setBreadcrumbPath, filterQuery, searchquery, setSearchquery, page, setPage, totalpages, setTotalpages, downloading, setDownloading, rowsPerPage, setRowsPerPage } = useGlobalState();
    // ..........

    //  datas states
    const [globalSearch, setGlobalSearch] = useState('')
    const [selectedGST, setSelectedGST] = useState<any>([])
    const [selectedRowsArray, setSelectedRowsArray] = useState<any>([])
    const [showsearch, setShowsearch] = useState(false)
    const [months, setMonths] = useState<string[]>([]);
    const settings = ['Sort by Due date', 'Sort by Notice date', 'Sort by Tax period'];
    const tabitem = [{ label: 'GSTR 1' }, { label: 'GSTR 3B' }]
    const currentYear = new Date().getFullYear();
    // ..........

    // reference states
    const tableListData = useRef(false)
    const [changeview, setChangeview] = useState(false);
    const [tabview, setTabview] = useState(false);
    const [isMobile, setIsMobile] = useState<boolean>(false);
    const [complianceData, setComplianceData] = useState<any>([])
    const [sourcedata, setSourcedata] = useState<any>([])
    const navigate = useNavigate()
    // ..........

    const loadComplianceData = useCallback(() => {
        let query: any = {
            by_page: true,
            page: page,
            page_size: rowsPerPage
        }
        if (searchquery) {
            query['search'] = searchquery;
        }
        if (filterStatus === 'Filed') {
            query['is_filled_status'] = true
        }
        if (filterStatus === 'To be Filed') {
            query['is_filled_status'] = false
        }
        setLoading(true)

        setBreadcrumbPath('home/compliance/compliance monitor')
        serviceConfig.get("compliance_data", true, query, null).then((data: any) => {
            setLoading(false)
            setComplianceData(data.results || data.data)
            setSourcedata(data.results || data.data)
            setTotalpages(data.count)
        }, (err: any) => {
            console.log(err)
            setLoading(false)
            if (err.message === "Request failed with status code 401") {
                navigate('/login')
                localStorage.clear()
                window.location.reload();
            }
        })
    }, [page, rowsPerPage, filterQuery, searchquery, filterStatus])

    useEffect(() => {
        loadComplianceData()
    }, [loadComplianceData])

    // search 
    const search = (value: any) => {
        if (sourcedata[0].notice) {
            setNoticeObj(true);
        }
        const filterTable = sourcedata.filter((o: any) =>
            Object.keys(noticeObj ? o.notice : o).some(k => {
                if (k !== 'gst' && k !== 'case_data') {
                    return String(noticeObj ? o.notice[k] : o[k])
                        .toLowerCase()
                        .includes(value.toLowerCase())
                } else {
                    return Object.keys(noticeObj ? o.notice[k] : o[k]).some(j => String(noticeObj ? o.notice[k][j] : o[k][j])
                        .toLowerCase()
                        .includes(value.toLowerCase()))
                }
            }
            )
        );
        setComplianceData(filterTable)
    }

    const handleGlobalSearchChange = (e: any) => {
        let value = e.target.value;
        if (!value) {
            setSearchquery(value)
            setLocalsearch(value)
        } else {
            setLocalsearch(value)
        }


        // if (userRole === 'Super Admin') {
        //     let query: any = {
        //         trade_name: [value]
        //     };
        //     setFilterQuery(query);
        // } else {
        //     search(value);
        // }

    }
    const searchclick = (value: any) => {
        setPage(1)
        setSearchquery(value)
    }
    // ..........

    // download gst function
    const downloadGST = async (query: any, loading: any) => {
        if (query === 'compliance_list') {
            downloadServices.compliance_list(page, rowsPerPage, JSON.stringify(filterQuery) !== '{}' ? filterQuery : null, loading);
        }
        else
            downloadServices.download_notices(null);
    }
    // ........

    // table action btns
    const btnClick = (data: any, query: any, btnfunction: any) => {

        if (data === 'download' && !downloading) {
            setDownloading(true)
            downloadGST(query, setDownloading);
        }


    }

    // ................

    // button details props
    const buttondetails: any = [
        {
            type: 'download',
            name: 'Download',
            theme: 'theme4',
            conditions: ['if_list'],
            downloadquery: 'compliance_list'
        }
    ]
    // ...........

    // last six month data get function
    useEffect(() => {
        const currentDate = new Date()
        setMonths(getLastSixMonths(currentDate))
        initializeFilters(getLastSixMonths(currentDate));
    }, [])

    const getLastSixMonths = (date: Date): string[] => {
        const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
        const result: string[] = [];

        for (let i = 5; i >= 0; i--) {
            const pastMonth = new Date(date.getFullYear(), date.getMonth() - i, 1);
            result.push(monthNames[pastMonth.getMonth()]);
        }

        return result
    }

    const initializeFilters = (months: string[]) => {
        const initialFilters: { [key: string]: string } = {};
        months.forEach(month => {
            initialFilters[month] = 'All'; // Default is 'All' (no filtering)
        });
        setFilters(initialFilters);
    };

    const checkStatus = (filedData: ReturnData[], month: string, year: string): string => {
        const filing = filedData?.find(data => data.taxp === month && data.fy === year);
        return filing ? filing.status : 'NA';
    };


    const handleFilterChange = (month: string, value: string) => {
        setFilters(prevFilters => ({
            ...prevFilters,
            [month]: value
        }));
    };

    const filterRows = (status: string, filter: string) => {
        if (filter === 'All') return true;
        if (filter === 'Filed' && status === 'Filed') return true;
        if (filter === 'Not Filed' && status !== 'Filed' && status !== 'NA') return true;
        if (filter === 'NA' && status === 'NA') return true;
        return false;
    };

    const shouldShowCompany = (filedData: ReturnData[], year: string) => {
        if (!filedData || filedData.length === 0) {
            return false;
        }

        // Check if any month satisfies the filter condition
        return months.some(month => {
            const status = checkStatus(filedData, month, year);
            return filterRows(status, filters[month]);
        });
    };

    // ......

    return (
        <div className='complaince-overall-container'>
            <div style={{ marginTop: '1%' }}>
                <Breadcrumbs />
            </div>
            <div className='complaince-content-container'>
                <div className="z-left-mobile-container">
                    {!showsearch &&
                        <h2 className='z-gst-head'>
                            Compliance Monitor
                        </h2>}
                    {!showsearch && < img
                        src={searchIcon}
                        className='mob-search-icon'
                        style={{ height: "80%", cursor: "pointer", borderRadius: 20 }}
                        alt='password icon'
                        onClick={() => setShowsearch(true)} />}
                    {showsearch &&
                        <Input
                            className='z-table_global_search'
                            value={searchquery}
                            onChange={handleGlobalSearchChange}
                            placeholder='Type here to search...'
                            suffix={<img
                                onClick={() => setShowsearch(false)}
                                src={searchIcon}
                                style={{ height: "80%", cursor: "pointer", borderRadius: 20 }}
                                alt='password icon' />}
                        />}
                </div>
                <div className='z-left-container'>
                    <h2 className='z-gst-head'>
                        Compliance Monitor
                    </h2>
                </div>
                <div className="z-right-container">

                    <div className='tab_cover'>
                        {tabitem.map((item: any) => (
                            <div className={tab === item.label ? 'tab active' : 'tab'} onClick={() => { setTab(item.label) }} key={item}>
                                <span>{item.label}</span>
                            </div>
                        ))}
                    </div>

                    {complianceData.length !== 0 && buttondetails.map((button: any) => {
                        return (
                            <Button
                                id="z-common-gst-solution-button"
                                className={button.type === 'get' ? `${button.theme} get-btn` : button.theme}
                                key={button.name}
                                onClick={(e) => btnClick(button.type, button.downloadquery, button.function)}
                            >
                                {button.type === 'download' && <img className="icon-btn" src={downloadIcon} alt='action' />}
                            </Button>
                        );
                    })}
                </div>
            </div>
            <div className='compliance-table-header'>
                <span className='company-column'>Company Name</span>
                <span className='gst-column'>GSTIN</span>
                <div className='status-column'>
                    {months.map((month, index) => (
                        <span key={index}>
                            {month}
                            <select
                                value={filters[month]}
                                onChange={(e) => handleFilterChange(month, e.target.value)}
                            >
                                <option value="All">All</option>
                                <option value="Filed">Filed</option>
                                <option value="Not Filed">Not Filed</option>
                                <option value="NA">NA</option>
                            </select>
                        </span>
                    ))}
                </div>
            </div>
            <div className='compliance-data-container'>
                {complianceData && complianceData.map((compli: any, idx: any) => {
                    const year = `${currentYear}-${currentYear + 1}`;
                    const filedData = tab === 'GSTR 1' ? compli?.filed_data?.GSTR1 : compli?.filed_data?.GSTR3B;

                    if (!shouldShowCompany(filedData, year)) {
                        // If no filtered month matches, don't render this company
                        return null;
                    }

                    return (
                        <div className='compliance-table-content' key={idx}>
                            <div className='company-table-column'>
                                <span className='comapany-line'>{compli.gst.trade_name}</span>
                            </div>
                            <div className='gst-table-column'>
                                <span className='gst-line'>{compli.gst.gst_no}</span>
                            </div>
                            <div className='status-table-column'>
                                <div className='status-first-row'>
                                    <div className='row-card-wrap'>
                                        {months.map((month, idx) => {
                                            const status = checkStatus(filedData || [], month, year);
                                            const filtered = filterRows(status, filters[month]);

                                            // Define a class based on the status
                                            const rowCardClass = filtered
                                                ? status === 'Filed'
                                                    ? 'row-card row-card-filled'
                                                    : status === 'NA'
                                                        ? 'row-card row-card-na'
                                                        : 'row-card row-card-not-filled'
                                                : 'row-card'; // Default class if not filtered

                                            return (
                                                <div key={idx} className={rowCardClass}>
                                                    <span className='row-card-subline'>{filtered ? status : '--'}</span>
                                                </div>
                                            );
                                        })}
                                    </div>
                                </div>
                            </div>
                            {/* {compli && compli.filed_data ?
                                <StatusTable compli={compli} tab={tab} months={months} checkStatus={checkStatus} filters={filters} filterRows={filterRows} /> :
                                <div className='status-table-column'>
                                    <div className={'status-first-row'} >
                                        <div className='row-card-wrap'>
                                            {months.map((month: any, idx: any) => {
                                                const status = tab === 'GSTR 1'
                                                    ? checkStatus(compli.form_names[0], month, `${currentYear}-${currentYear + 1}`)
                                                    : checkStatus(compli.form_names[1], month, `${currentYear}-${currentYear + 1}`);

                                                return filterRows(status, filters[month]) ? (
                                                    <div key={idx} className='row-card'>
                                                        <span className='row-card-subline'>{status}</span>
                                                    </div>
                                                ) : null;
                                            })}
                                        </div>
                                    </div>
                                </div>
                            } */}
                        </div>
                    )
                })}
                {loading &&
                    <Spin spinning={loading} size='default' style={{ maxWidth: '100%', display: 'flex', position: 'absolute', marginTop: '10%' }}>
                    </Spin>
                }
                {!loading && complianceData.length === 0 && <span className='nodata-line'>No data</span>}
            </div>
            {/* {
                complianceData.length === 0 ? <></> : <div className='compliance-table-footer'>
                    <Pagination
                        pageSizeOptions={['5', '10', '20', '50', '100']}
                        total={totalpages}
                        current={page}
                        showTotal={(total, range) => `${isMobile ? '' : 'Showing'} ${range[0]} of ${total} ${isMobile ? '' : 'results'}`}
                        pageSize={rowsPerPage}
                        showSizeChanger={true}
                        onChange={(page, pageSize) => {
                            setPage(page);
                            setRowsPerPage(pageSize);
                        }}
                        onShowSizeChange={(current, size) => {
                            setPage(current);
                            setRowsPerPage(size);
                        }}
                    />
                </div>
            } */}
        </div >
    )
}