// react 
import { Link, useNavigate } from "react-router-dom";
import React, { useState, useEffect } from "react";
// ..........

// scss file
import "./register.scss"
// ..........

// package components
import { FormHelperText, OutlinedInput, TextField, ThemeProvider, createTheme } from "@mui/material";
// ..........

// child components
import { serviceConfig } from "../../../core/services";
import { NotifyAlert } from "../../../components/notify_alert";
// ..........

// icons
import userIcon from "../../../assets/register/user.png";
import phoneIcon from "../../../assets/register/phone.png";
import mailIcon from "../../../assets/register/mail.png";
import validCode from "../../../assets/register/validCode.png";
// ..........

export const Register = () => {

    // form values states
    const [user_name, setUsername] = useState('')
    const [user_email, setUserEmail] = useState('')
    const [user_mobile, setUserMobile] = useState('')
    const [user_coupen, setUserCoupen] = useState('')
    const [err_user_name, setErrUserName] = useState('')
    const [err_user_mobile, setErrUserMobile] = useState('')
    const [err_user_email, setErruserEmail] = useState('')
    const [usertype, setUsertype] = useState('Taxpayer')
    // ..........

    // alerts and modal states
    const [notifyOpen, setNotifyOpen] = useState(false)
    const [notifyType, setNotifyType] = useState<any>();
    const [notifyMessage, setNotifyMessage] = useState('')
    // ..........

    // loading state
    const [loading, setLoading] = useState(false)
    // ..........

    // data states
    const [accept_cf, setCf] = useState(false)
    const [accept_policy, setPolicy] = useState(false)
    // ..........

    // reference states
    const navigate = useNavigate();
    // ..........

    useEffect(() => {
        const listener = (event: any) => {
            if (event.code === "Enter" || event.code === "NumpadEnter") {
                console.log("Enter key was pressed. Run your function.");
                event.preventDefault();
                registerUser()
            }
        };
        document.addEventListener("keydown", listener);
        return () => {
            document.removeEventListener("keydown", listener);
        };
    }, [user_name, user_email, user_mobile, user_coupen, accept_policy]);

    // form value validation function
    const validateMobileNumber = (number: any) => {
        const pattern = /^(?:(?:\+|0{0,2})91(\s*[\-]\s*)?|[0]?)?[6789]\d{9}$/;
        console.log("pattern test", pattern.test(number))
        return pattern.test(number);
    };
    const handleMobileChange = (e: any) => {
        const newval = e.target.value;
        setUserMobile(newval);
        if (newval !== '') {
            setErrUserMobile(validateMobileNumber(newval) ? '' : '*Invalid Mobile number');
        } else {
            setErrUserMobile("*Please enter mobile number")
        }
    }

    const validateEmail = (email: any) => {
        const pattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        return pattern.test(email);
    }
    const handleEmailChange = (e: any) => {
        const newval = e.target.value;
        setUserEmail(newval);
        if (newval !== '') {
            setErruserEmail(validateEmail(newval) ? '' : '*Invalid E-Mail id')
        } else {
            setErruserEmail("*Please enter mail id")
        }
    }

    const handleUsername = (e: any) => {
        const newval = e.target.value;
        setUsername(newval);
        if (newval !== '') {
            setErrUserName('')
        }
    }
    // ..........

    // Register submission function
    const registerUser = (e?: any) => {
        if (!loading) {
            if (!user_name || !user_email || !user_mobile) {

                !user_name && setErrUserName("*User name required");
                !user_mobile && setErrUserMobile("*Mobile number required");
                !user_email && setErruserEmail("*E-Mail id required")

            } else if (!accept_policy) {
                setNotifyType("error");
                setNotifyMessage("Please accept our policies to proceed!");
                setNotifyOpen(true);
            } else if (!err_user_email && !err_user_mobile && !err_user_name) {
                setLoading(true)
                let req_body = {
                    "user_name": user_name,
                    "email": user_email.toLowerCase(),
                    "contact_number": user_mobile,
                    "agent_partner_code": user_coupen,
                    "user_type": usertype,
                    'is_cf': user_coupen ? false : accept_cf
                }

                serviceConfig.post("register_user", true, req_body, null).then((data: any) => {
                    localStorage.setItem('user_id', data.data.user_id)
                    navigate("/otp-verify");
                    setUsername('')
                    setUserEmail('')
                    setUserEmail('')
                    setPolicy(false)
                    setLoading(false)
                }, (err: any) => {
                    setLoading(false)
                    console.log(err)
                    setNotifyType("error");
                    setNotifyMessage("Mail already exists!");
                    setNotifyOpen(true);
                })
            }
        }
    }
    // ..........

    // navigate to privacy page
    const goToPrivayPolicy = () => {
        navigate('/privacy-policy')
    }
    // ..........

    // custom theming function
    const inputtheme = createTheme({
        palette: {
            primary: {
                main: '#9a9999',
            }
        },
    });
    // ..........

    // usertype change function
    const handlechange = (event: any) => {
        const { value } = event.target;
        setUsertype(value);
    };

    const userType: any = [
       'Taxpayer',
        'GST Practitioner / Professional',
    ]
    // ..........

    return (
        <>
            <div className="register-container">
                <h4 className="terminal-window-mainline">Registration</h4>
                <p className="terminal-window-subline">Please enter a valid E-mail id and mobile number to register</p>
                <div className="fields">
                    <div className="row">
                        <div className="col-sm-12 input-group ">
                            <label className="input-label">User name</label>
                            <ThemeProvider theme={inputtheme}>
                                <OutlinedInput
                                    className='inputfield'
                                    id="outlined-adornment-weight"
                                    placeholder='Enter your user name'
                                    value={user_name}
                                    error={!!err_user_name}
                                    onChange={handleUsername}
                                    // onBlur={checkUserName}
                                    startAdornment={<img className="input-icon" src={userIcon} alt="user_icon" />}
                                    aria-describedby="outlined-weight-helper-text"
                                />
                                <FormHelperText style={{ color: '#E10000', fontSize: '1.8vmin ', textAlign: 'right' }} id='error-line'>{err_user_name}</FormHelperText>
                            </ThemeProvider>

                        </div>

                        <div className={err_user_name ? "col-sm-12 input-group error-margin" : "col-sm-12 input-group"} style={{ marginTop: err_user_name ? '0' : '' }}>
                            <label className="input-label">Email</label>
                            <ThemeProvider theme={inputtheme}>
                                <OutlinedInput
                                    className='inputfield'
                                    id="outlined-adornment-weight"
                                    placeholder='Enter your registered mail id'
                                    value={user_email}
                                    error={!!err_user_email}
                                    onChange={handleEmailChange}
                                    startAdornment={<img className="input-icon" src={mailIcon} alt="email_icon" />}
                                    aria-describedby="outlined-weight-helper-text"
                                />
                                <FormHelperText style={{ color: '#E10000', fontSize: '1.8vmin ', textAlign: 'right', position: 'relative' }} id='error-line'>{err_user_email}</FormHelperText>
                            </ThemeProvider>

                        </div>

                        <div className={err_user_email ? "col-sm-12 input-group error-margin" : "col-sm-12 input-group"} style={{ marginTop: err_user_email ? '0' : '' }}>
                            <label className="input-label">Mobile Number</label>
                            <ThemeProvider theme={inputtheme}>
                                <OutlinedInput
                                    className='inputfield'
                                    id="outlined-adornment-weight"
                                    placeholder='Enter your mobile number'
                                    value={user_mobile}
                                    error={!!err_user_mobile}
                                    onChange={handleMobileChange}
                                    startAdornment={<img className="input-icon" src={phoneIcon} alt="mobile_icon" />}
                                    aria-describedby="outlined-weight-helper-text"
                                />
                                <FormHelperText style={{ color: '#E10000', fontSize: '1.8vmin ', textAlign: 'right', position: 'relative' }} id='error-line'>{err_user_mobile}</FormHelperText>
                            </ThemeProvider>

                        </div>

                        <div className="col-sm-12 input-group" >
                            <label className="input-label">User Type</label>
                            <TextField
                                fullWidth
                                focused
                                select
                                SelectProps={{ native: true, }}
                                variant='outlined'
                                name="casetype"
                                value={usertype}
                                onBlur={handlechange}
                                onChange={handlechange}
                                autoComplete='off'
                                style={{ margin: '0%'}}
                            >
                                <option value='' disabled>...</option>
                                {userType.map((type: any) =>
                                    <option key={type} value={type}>{type}</option>
                                )}
                            </TextField>
                        </div>

                        <div className={err_user_mobile ? "col-sm-12 input-group error-margin" : "col-sm-12 input-group"} style={{ marginTop: err_user_mobile ? '0' : '' }}>
                            <label className="input-label">Agent Partner Code (optional)</label>
                            <ThemeProvider theme={inputtheme}>
                                <OutlinedInput
                                    className='inputfield'
                                    id="outlined-adornment-weight"
                                    placeholder='Enter valid code'
                                    value={user_coupen}
                                    onChange={(e) => setUserCoupen(e.target.value)}
                                    startAdornment={<img className="input-icon" src={validCode} alt="agent_icon" />}
                                    aria-describedby="outlined-weight-helper-text"
                                />
                            </ThemeProvider>

                        </div>

                        <div className="col-sm-12  privacy_policy">
                            <span className={!accept_policy ? 'check_box' : 'checked_box'} onClick={() => setPolicy(!accept_policy)}>
                                <span className="input-error-line">
                                    <div className="checkmark_stem"></div>
                                    <div className="checkmark_kick"></div>
                                </span>
                            </span>
                            <div className="title">By proceeding, I accept the <span className="privacy-innerline" onClick={goToPrivayPolicy}>privacy policy</span></div>
                        </div>

                        <div className="col-sm-12 input-group register_btn">
                            <button className="teminal-primary-btn register" onClick={registerUser}>{!loading ? 'Register' : 'Loading...'}</button>
                        </div>
                        <div className="col-sm-12 login-link" style={{ textAlign: 'center', marginTop: '4%' }}>
                            <Link className="terminal-link" to='../'>Back to Login</Link>
                        </div>

                    </div>
                </div>
            </div>
            <NotifyAlert notifyOpen={notifyOpen} changeStatus={setNotifyOpen} notifyMessage={notifyMessage} notifyType={notifyType}></NotifyAlert>
        </>
    )
}